// Taken from https://gitlab.com/minted/e-commerce/custom-fine-art/-/blob/master/src/components/MintedField.jsx
// Will eventually exist in @minted-components
import { Field } from '@oneform/react';
import React from 'react';
import {
  memo,
  useCallback,
} from 'react';

const MintedField = (
  fieldProps
) => {
  const translateProps = (
    useCallback(
      ({
        errorMessages,
        fieldName,
        isChecked,
        isVisited,
        updateFieldValue,
        value,
        visitField,
      }) => ({
        checked: isChecked,
        error: (
          typeof errorMessages[0] === 'string'
            ? errorMessages[0]
            : (
              errorMessages[0]
                ? ' '
                : ''
            )
        ),
        name: fieldName,
        onBlur: visitField,
        onChange: updateFieldValue,
        touched: isVisited,
        value,
      }),
      [],
    )
  );

  return (
    <Field
      {...fieldProps}
      translateProps={translateProps}
    />
  );
};

export default memo(MintedField);
