
import { css } from '@emotion/react';
import {
  Button, theme, styleUtils,
} from '@minted/minted-components';
import isEqual from 'lodash/isEqual';
import { array, string } from 'prop-types';
import React from 'react';

import { RichTextContent } from '../../content/components';
import { BLANK_SLATE_DOCUMENT } from '../../core/constants';

const registriesStyles = {
  description: css`
    width: 100%;
    ${theme.typeStyles.bodyExtraSmallStyles};
  `,
  image: css`
    display: block;
    max-height: ${styleUtils.rem(131)};
    max-width: ${styleUtils.rem(131)};
`,
  imageNoCursor: css`
    cursor: default;
`,
  imageWrapper: css`
    display: flex;
    align-items: center;
    cursor: pointer;

    height: ${styleUtils.rem(131)};
    max-width: ${styleUtils.rem(131)};

    margin-right: ${theme.spacing.x4};
    // Image moves from left to top
    ${theme.media.lessThan(theme.breakpoints.small)(`
      margin-bottom: ${theme.spacing.x2};
      margin-right: 0;
    `)};
  `,
  registryWrapper: css`
    box-shadow: 0 1px 4px 0 #00000040;
    background-color: ${theme.colors.gray100};
    color: ${theme.colors.gray700};

    padding: ${theme.spacing.x8};
    margin-bottom: ${theme.spacing.x4};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    box-sizing: border-box;
    max-width: ${styleUtils.rem(728)};
    ${theme.media.lessThan(theme.breakpoints.small)(`
      max-width: ${styleUtils.rem(335)};
      flex-direction: column;
      padding: ${theme.spacing.x4};
    `)};
  `,
  titleAndDescriptionWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    ${theme.media.lessThan(theme.breakpoints.small)(`
        align-items: center;
        text-align: center;
    `)};
  `,
  visitButton: css`
    margin-left: auto;
    padding-left: ${theme.spacing.x2};
    // Button moves from right to bottom
    ${theme.media.lessThan(theme.breakpoints.small)(`
        padding-left: 0;
        padding-top: ${theme.spacing.x2};
        margin-right: auto;
    `)};
  `,
};

const Registry = ({
  description,
  image,
  title,
  url,
}) => (
  <div
    css={registriesStyles.registryWrapper}
    data-cy="giftSection"
  >
    {
      image && (
        <div css={registriesStyles.imageWrapper}>
          <img
            alt={title}
            css={registriesStyles.image}
            src={image}
          />
        </div>
      )
    }
    <div css={registriesStyles.titleAndDescriptionWrapper}>
      <div css={theme.typeStyles.titleLargeStyles}>
        {title}
      </div>
      {
        description && !isEqual(description, BLANK_SLATE_DOCUMENT) && (
          <div css={registriesStyles.description}>
            <RichTextContent
              value={description}
            />
          </div>
        )
      }
    </div>
    {
      url && (
        <div css={registriesStyles.visitButton}>
          <Button
            // href has to start with http or the browser appends it to the current URL
            href={url.startsWith('http') ? url : `https://${url}`}
            target="_blank"
            text="VIEW REGISTRY"
          />
        </div>
      )
    }
  </div>
);

Registry.propTypes = {
  /** Passed as value prop to https://docs.slatejs.org/libraries/slate-react/slate */
  description: array,
  image: string,
  title: string.isRequired,
  url: string,
};

export default Registry;
