import { theme } from '@minted/minted-components';
import { format as formatDate } from 'date-fns';
import React from 'react';

export const formatAddress = ({
  address1, address2, city, state, zipCode,
}) => `${address1}, ${address2 ? address2 + ', ' : ''} ${city}, ${state} ${zipCode}`;

export const formatEventDate = (date) => {
  // incoming date format: 'MM/dd/yyyy'
  const [
    month,
    day,
    year,
  ] = date.split('/');

  if (!(day && month && year)) {
    return '';
  }

  const actualDate = new Date(Number(year), Number(month) - 1, Number(day));

  return formatDate(actualDate, 'MMMM d, yyyy');
};

export const getEventDateTimeComponent = (date, time, endDate, endTime, inline, styled = true) => {
  // Incoming date format: 'MM/dd/yyyy'
  let display;

  // Date is required to be valued
  if (!date) {
    return null;
  }

  // No start time means it's an all-day event
  if (!time) {
    display = formatEventDate(date);
  } else if (!endDate || date === endDate) {
    // Display end date/time only if it's valued and different from start time
    if (!endTime || time === endTime) {
      display = `${time}, ${formatEventDate(date)}`;
    } else {
      display = `${time} - ${endTime}, ${formatEventDate(date)}`;
    }
  }

  if (display) {
    return inline
      ? (
        <p css={styled && theme.typeStyles.bodyMediumStyles}>
          {display}
        </p>
      ) :
      (
        <div css={styled && theme.typeStyles.bodyMediumStyles}>
          {display}
        </div>
      );
  }

  // Start and end date differ, display the full string
  return inline
    ? (
      <p css={styled && theme.typeStyles.bodyMediumStyles}>
        {`${time}, ${formatEventDate(date)} - ${endTime}, ${formatEventDate(endDate)}`}
      </p>
    ) :
    (
      <div css={styled && theme.typeStyles.bodyMediumStyles}>
        <div>
          {`${time}, ${formatEventDate(date)} -`}
        </div>
        <div>
          {`${endTime}, ${formatEventDate(endDate)}`}
        </div>
      </div>
    );
};
