export const SITE_TYPES = {
  HOLIDAY: 'holiday',
  WEDDING: 'wedding',
};

export const VIDEO_LIMIT = 20;
export const PHOTO_LIMIT = 1000;

export const MAX_DOMAIN_LENGTH = 64;
export const MAX_PASSWORD_LENGTH = 30;

export const GUESTS_TOKEN_EXPIRE_TIME_SECONDS = 60 * 60 * 24 * 3; // 3 Days

export const BLANK_SLATE_DOCUMENT = [
  {
    children: [
      {
        text: '',
      },
    ],
    type: 'paragraph',
  },
];
