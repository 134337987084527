import { css } from '@emotion/react';
import { Button } from '@minted/fancyclothes';
import config from 'config';
import {
  arrayOf, array, shape, string,
} from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Event from './Event';
import { contentConfiguration, contentStyles } from '../../content';
import Track from '../../core/instrumentation';
import { WrapperMedium } from '../../layout/website';
import EventMap from '../../map/website/Map';
import MapContainer from '../../map/website/MapContainer';
import Page from '../../pages/website/Page';
import FancyClothesContext from '../../website/components/FancyClothesContext';
import { focusLocation } from '../actions';
import { formatAddress } from '../utils';

const googleMapsApiKey = config.get('googleMapsApiKey');

const buttonContainerStyles = css`
  display: flex;
  justify-content: center;
`;

class Events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasValidLocations: false,
      isMapExpanded: false,
      showMap: false,
    };
  }

  onExpandToggle() {
    this.setState({
      isMapExpanded: !this.state.isMapExpanded,
    });
  }

  onShowToggle() {
    Track.emit({
      event: 'map_button_click',
    });

    this.setState({
      showMap: true,
    });
  }

  onLocationsGeocoded = (hasValidLocations) => {
    this.setState({
      hasValidLocations,
    });
  };

  render() {
    const {
      configuration,
      dispatch,
      events,
      focusedLocationIndex,
      id,
      title,
    } = this.props;
    const {
      hasValidLocations, isMapExpanded, showMap,
    } = this.state;
    let hasAddresses;

    const locations = events.map((event) => {
      const address = event.hasPhysicalAddress ? formatAddress(event) : '';

      // Track if any locations have an address
      hasAddresses = hasAddresses || Boolean(address);

      return {
        ...event,
        address,
      };
    });

    return (
      <FancyClothesContext.Consumer>
        {
          () => (
            <Page
              id={id}
              title={title}
            >
              <WrapperMedium>
                <div css={isMapExpanded && contentStyles.hideMd}>
                  {
                    locations.map((item, index) => (
                      <Event
                        configuration={configuration}
                        index={index}
                        key={index}
                        showListNumber={configuration.showMap && hasValidLocations}
                        {...item}
                      />
                    ))
                  }
                </div>
                {
                  configuration.showMap && hasAddresses
                    ? (
                      showMap
                        ? (
                          <MapContainer
                            isExpanded={isMapExpanded}
                            onExpandToggle={this.onExpandToggle.bind(this)}
                          >
                            <EventMap
                              apiKey={googleMapsApiKey}
                              focusedLocationIndex={focusedLocationIndex}
                              focusLocation={(index) => dispatch(focusLocation(index))}
                              locations={locations}
                              onLocationsGeocoded={this.onLocationsGeocoded}
                            />
                          </MapContainer>
                        )
                        : (
                          <div css={buttonContainerStyles}>
                            <Button
                              onClick={this.onShowToggle.bind(this)}
                              text="Show Map"
                            />
                          </div>
                        )
                    )
                    : null
                }
              </WrapperMedium>
            </Page>
          )
        }
      </FancyClothesContext.Consumer>
    );
  }
}

Events.propTypes = {
  configuration: contentConfiguration.configurationPropTypes,
  events: arrayOf(
    shape({
      address1: string,
      address2: string,
      city: string,
      date: string.isRequired,
      description: array,
      state: string,
      time: string,
      title: string.isRequired,
      venue: string.isRequired,
      zipCode: string,
    }).isRequired
  ).isRequired,
  id: string.isRequired,
  title: string.isRequired,
};

const mapStateToProps = (state) => {
  const {
    focusedLocationIndex,
  } = state.events;

  return {
    focusedLocationIndex,
  };
};

export default connect(mapStateToProps)(Events);
