import { withDesign } from '@minted/fancyclothes';
import {
  arrayOf, func, string,
} from 'prop-types';
import { PureComponent } from 'react';

@withDesign('DesignProvider')
class ThemeExtractor extends PureComponent {
  render() {
    const {
      children,
      getRule,
      rules,
    } = this.props;

    const partialDesignObject = rules.reduce((acc, rule) => {
      try {
        acc[rule] = getRule(rule);
      } catch (error) {
        console.error(error);
      }

      return acc;
    }, {});

    return children(partialDesignObject);
  }
}

ThemeExtractor.propTypes = {
  children: func.isRequired,
  rules: arrayOf(string),
};

export default ThemeExtractor;
