export class APIError extends Error {
  constructor(message, response, request) {
    super();
    this.name = 'APIError';
    this.message = message;
    this.response = response;
    this.request = request;
    this.stack = (new Error()).stack;
  }
}
