import { css } from '@emotion/react';
import { theme } from '@minted/minted-components';
import React from 'react';

import { formatEventDate } from '../../events/utils';

const flexContainerStyles = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing.x4};
`;

const textStyles = css`
  ${theme.typeStyles.bodySmallStyles};
`;

export default ({
  event,
}) => (
  <div css={flexContainerStyles}>
    <div css={textStyles}>
      {formatEventDate(event.date)}
    </div>
    <div css={textStyles}>
      {event.time}
    </div>
  </div>
);
