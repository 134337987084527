import { css } from '@emotion/react';
import { MediumHeadline } from '@minted/fancyclothes';
import PropTypes from 'prop-types';
import React from 'react';

import PageContentSeparator from './PageContentSeparator';
import { lgMarginBottomStyles } from '../../neutral/styles/spacing';

const PageTitle = ({
  children, hideDivider = false,
}) => (
  <div css={css`text-align: center;`}>
    <div
      css={lgMarginBottomStyles}
      data-cy="heading"
    >
      <MediumHeadline>
        {children}
      </MediumHeadline>
    </div>
    <PageContentSeparator hideDivider={hideDivider} />
  </div>
);

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitle;
