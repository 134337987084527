import { css } from '@emotion/react';
import { DesignComponent } from '@minted/fancyclothes';
import { styleUtils, theme } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React from 'react';

class ListNumber extends DesignComponent {
  render() {
    const {
      children,
    } = this.props;
    const {
      fontFamily,
    } = this.getFontStyles({}, 'DesignProvider');

    const style = css`
      align-items: center;
      background-color: ${this.getRule('fontColor', 'DesignProvider')};
      border-radius: 50%;
      color: ${this.getRule('backgroundColor', 'DesignProvider')};
      display: flex;
      font-family: ${fontFamily};
      height: ${styleUtils.rem(30)};
      justify-content: center;
      text-align: center;
      width: ${styleUtils.rem(30)};
      ${theme.media.lessThan(theme.breakpoints.medium)(`
        display: none;
      `)}
    `;
    const customStyle = this.getFontSizeStyles(16, {}, 'DesignProvider');

    return (
      <div
        css={
          [
            style,
            customStyle,
          ]
        }
      >
        {children}
      </div>
    );
  }
}

ListNumber.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListNumber;
