import { css } from '@emotion/react';
import { MediumHeadline } from '@minted/fancyclothes';
import { styleUtils, theme  } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { RichTextContent } from '../../content/components';
import MediaGallery from '../../media/website/MediaGallery';
import RefSource from '../../ref-monitor/RefSource';
import { SIMPLIFIED_WEBSITE_CONTENT_WIDTH, VIDEO_PROCESSING_WAIT_TIME } from '../constants';
import { cloudinaryUrl } from '../utils';
import { sortByCreatedAtAndPosition } from '../utils';

const sectionWidth = 1170;

const sectionStyles = css`
    box-sizing: border-box;
    margin: ${theme.spacing.x6} auto;
    max-width: ${styleUtils.rem(sectionWidth)};
    overflow-wrap: break-word;
`;

const oneColumnContentStyles = css`
  max-width: ${SIMPLIFIED_WEBSITE_CONTENT_WIDTH};
  margin-left: auto;
  margin-right: auto;
`;

const twoColumnContentStyles = css`
  max-width: ${styleUtils.rem(sectionWidth / 3 * 2)};
  margin-left: auto;
  margin-right: auto;
`;

const textSectionStyles = css`
  // Images are full width on mobile, but text shouldn't be
  padding-left: ${theme.spacing.x4};
  padding-right: ${theme.spacing.x4};
`;

const descriptionStyles = css`
  // Images are full width on mobile, but text shouldn't be
  padding-left: ${theme.spacing.x4};
  padding-right: ${theme.spacing.x4};
  margin-top: ${styleUtils.rem(20)};
  margin-bottom: ${styleUtils.rem(10)};
`;

const titleStyles = css`
  margin-bottom: ${styleUtils.rem(20)};
  text-align: center;
`;

const hasProcessed = (createdAt) => {
  const date = new Date(`${createdAt}+00:00`);

  return (date.getTime() + VIDEO_PROCESSING_WAIT_TIME) < Date.now();
};

const renderContentSection = ({
  key, ref, ...props
}) => (
  <div
    key={key}
    ref={ref}
  >
    <div
      css={sectionStyles}
    >
      <div
        css={props.title ? titleStyles : null}
        data-cy="subheading"
      >
        <MediumHeadline css={css`display: block;`}>
          {props.title}
        </MediumHeadline>
      </div>
      <div data-cy="body">
        {props.sectionContent}
      </div>
    </div>
  </div>
);

const ContentSection = ({
  connectRef, isMobileView, section, sectionMedia,
}) => {
  let sectionContent;

  if (section.sectionType === 'gallery') {
    const mediaList = sectionMedia.map((media) => ({
      ...media,
      hasProcessed: media.mediaType === 'video' ? hasProcessed(media.uploadedAt || media.createdAt) : true,
      original: media.mediaType === 'video' ? cloudinaryUrl(media.publicId, 'jpg', 'h_2048,w_2048,c_fit') : media.original,
    }));

    let style;

    if (section.galleryMode === 'slideshow' || section.galleryMedia.length === 1) {
      style = oneColumnContentStyles;
    } else if (section.galleryMedia.length === 2) {
      style = twoColumnContentStyles;
    }

    sectionContent = (
      <div css={style}>
        <div css={descriptionStyles}>
          <RichTextContent
            value={section.content || ''}
          />
        </div>
        <MediaGallery
          isMobileView={isMobileView}
          isPreview={section.galleryMode === 'slideshow'}
          media={mediaList}
        />
      </div>
    );
  } else {
    sectionContent = (
      <div css={textSectionStyles}>
        <RichTextContent
          value={section.content || ''}
        />
      </div>
    );
  };

  const baseProps = {
    sectionContent,
    title: section.title,
  };

  return connectRef(section.id || 'new', 'pages', renderContentSection(baseProps));
};

ContentSection.propTypes = {
  section: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const {
    resources,
  } = state;
  const {
    section,
  } = props;

  const media = section.galleryMedia
    ? section.galleryMedia.filter((item) => !Boolean(item.softDeletedAt) &&
    (Boolean(resources.photos.byId[item.id]) || Boolean(resources.videos.byId[item.id])))
    : [];

  const sortedMedia = sortByCreatedAtAndPosition(media);
  let sectionMedia = sortedMedia.map((media) => {
    if (media.mediaType === 'photo') {
      const storedData = resources.photos.byId[media.id];

      // Adding timestamp query parameter to images to make sure we get the most recent version from S3 and not the cache
      return {
        ...storedData,
        ...media,
        original: storedData.original,
      };
    }

    return {
      ...resources.videos.byId[media.id],
      ...media,
    };
  });

  sectionMedia = sectionMedia.filter((media) => media);

  return {
    sectionMedia,
  };
};

export default RefSource(connect(mapStateToProps)(ContentSection));
