import PropTypes from 'prop-types';
import React, { Children, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from 'redux-router5';

import MultiPageTransitionBackend from './MultiPageTransitionBackend';
import PageTransitionContext from './PageTransitionContext';
import SinglePageTransitionBackend from './SinglePageTransitionBackend';
import { selectSite } from '../selectors';

class PageTransitionManager extends Component {
  constructor(props, context) {
    super(props, context);

    this.refManager = context.refManager;
    this.scroller = context.scroller;

    this.transitionTo = this.transitionTo.bind(this);
    this.setBackend(props.pageConfiguration);
  }

  setBackend(pageConfiguration) {
    if (pageConfiguration === 'multi_page') {
      this.backend = new MultiPageTransitionBackend();
    } else {
      this.backend = new SinglePageTransitionBackend(this.refManager, this.props.navigateTo, this.scroller, this.props.siteType);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.pageConfiguration !== this.props.pageConfiguration) {
      this.backend.disable();
      this.setBackend(this.props.pageConfiguration);
      this.backend.enable({
        currentRoute: this.props.currentRoute,
      });
    }
  }

  transitionTo(name) {
    this.backend.transitionTo(name);
    this.props.navigateTo(name);
  }

  componentDidMount() {
    if (!this.props.isCustomizePage) {
      this.enableBackend();
    }
  }

  componentWillUnmount() {
    this.backend.disable();
  }

  enableBackend = () => {
    this.backend.enable(
      {
        currentRoute: this.props.currentRoute,
        isCustomizePage: this.props.isCustomizePage,
        isInitialLoad: true,
      }
    );
  }

  getChildContext() {
    return {
      transitionTo: this.transitionTo,
    };
  }

  getProviderValue = () => {
    if (!this.providerValue) {
      this.providerValue = {
        enableBackend: this.enableBackend,
        scrollTo: this.backend.transitionTo,
      };
    }

    return this.providerValue;
  }

  render() {
    return (
      <PageTransitionContext.Provider value={this.getProviderValue()}>
        {Children.only(this.props.children)}
      </PageTransitionContext.Provider>
    );
  }
}

PageTransitionManager.childContextTypes = {
  transitionTo: PropTypes.func,
};

PageTransitionManager.propTypes = {
  isCustomizePage: PropTypes.bool,
};

PageTransitionManager.contextTypes = {
  refManager: PropTypes.object.isRequired,
  scroller: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    currentRoute: state.router.route,
    pageConfiguration: site.pageConfiguration,
    siteType: site.siteType,
  };
};

const bindActions = (dispatch) => ({
  navigateTo: bindActionCreators(actions.navigateTo, dispatch),
});

export { PageTransitionManager };
export default connect(mapStateToProps, bindActions)(PageTransitionManager);
