import { css } from '@emotion/react';
import { theme } from '@minted/minted-components';
import React from 'react';
import { connect } from 'react-redux';

import LegacyRSVPForm from './LegacyRSVPForm';
import {
  ContentText, ContentWrapper, HeadlineSmall,
} from '../../content/components';
import RSVPForm from '../../guest-management/forms/RSVPForm/RSVPForm';
import { WrapperLarge } from '../../layout/website';
import Page from '../../pages/website/Page';
import { selectSite } from '../../website/selectors';

const style = (textAlignment) => css`
  max-width: 500px;
  ${theme.media.greaterThan(theme.breakpoints.small)(`
    margin:
      20px
      ${textAlignment === 'left' || textAlignment === 'center' ? 'auto' : '20px'}
      20px
      ${textAlignment === 'right' || textAlignment === 'center' ? 'auto' : '20px'};
  `)}
`;

const rsvpPageStyles = {
  cookieError: css`
    ${theme.typeStyles.bodyMediumStyles};
    text-align: center;
    color: ${theme.colors.negative800};
    margin-bottom: ${theme.spacing.x2};
  `,
};

const RSVP = ({
  configuration,
  id,
  isPreview,
  isUsingGuestManagement,
  title,
}) => {
  const hasCookies = typeof window !== 'undefined' && window.navigator.cookieEnabled;

  return (
    <Page
      id={id}
      title={title}
    >
      <WrapperLarge>
        <ContentWrapper
          extraCss={
            [
              style(configuration.textAlignment),
            ]
          }
          imageAlignment="top"
          textAlignment={configuration.textAlignment}
        >
          <ContentText textAlignment={configuration.textAlignment}>
            <HeadlineSmall>
              Enter Your Name to RSVP
            </HeadlineSmall>
          </ContentText>
          {
            hasCookies
              ? (
                isUsingGuestManagement
                  ? (
                    <RSVPForm
                      configuration={configuration}
                      isPreview={isPreview}
                      onCancel={() => {}}
                      onSave={() => {}}
                    />
                  )
                  : (
                    <LegacyRSVPForm
                      isPreview={isPreview}
                    />
                  )
              )
              : (
                <div css={rsvpPageStyles.cookieError}>
                  Cookies are required to submit your RSVP. Please enable cookies in your browser.
                </div>
              )
          }
        </ContentWrapper>
      </WrapperLarge>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    isUsingGuestManagement: site.isUsingGuestManagement,
  };
};

export default connect(mapStateToProps)(RSVP);
