import PropTypes from 'prop-types';
import React from 'react';
import Linkify from 'react-linkify';

import { textAlignmentMap } from '../../content/configuration';

const propTypes = {
  text: PropTypes.string.isRequired,
  textAlignment: PropTypes.string,
};

const Paragraphs = ({
  text, textAlignment = 'center',
}) => (
  <div css={textAlignmentMap.get(textAlignment)}>
    {
      (typeof text === 'string')
        ? text.split('\n').map(
          (paragraph, index) => (
            <span key={index}>
              <Linkify>
                {paragraph}
              </Linkify>
              <br />
            </span>
          )
        )
        : null
    }
  </div>
);

Paragraphs.propTypes = propTypes;

export default Paragraphs;
