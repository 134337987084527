import { css } from '@emotion/react';
import { theme } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import SolidButton from './SolidButton';
import MapContainerRefContext from '../../website/components/MapContainerRefContext';

const styles = {
  button: css`
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;

    ${theme.media.greaterThan(theme.breakpoints.medium)(`
      display: block;
    `)}
  `,
  frame: css`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border: 8px solid #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  `,
  inner: {
    base: css`
      position: relative;
      height: 400px;
    `,
    expanded: css`
      ${theme.media.greaterThan(theme.breakpoints.medium)(`
        height: 600px;
      `)}
    `,
  },
};

const MapContainer = ({
  children, isExpanded, onExpandToggle,
}) => {
  const mapContainerRef = useContext(MapContainerRefContext);

  return (
    <div
      css={styles.frame}
      ref={mapContainerRef}
    >
      <div
        css={
          [
            styles.inner.base,
            isExpanded && styles.inner.expanded,
          ]
        }
      >
        {children}
        <div css={styles.button}>
          <SolidButton
            onClick={onExpandToggle}
            text={isExpanded ? 'Shrink Map' : 'Expand Map'}
          />
        </div>
      </div>
    </div>
  );
};

MapContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool,
  onExpandToggle: PropTypes.func.isRequired,
};

export default MapContainer;
