import { css } from '@emotion/react';
import { ActionLink } from '@minted/fancyclothes';
import { theme } from '@minted/minted-components';
import {
  bool, array, string,
} from 'prop-types';
import React from 'react';

import EventDetail from './EventDetail';
import ListNumber from './ListNumber';
import { contentConfiguration, contentStyles } from '../../content';
import {
  ContentText,
  ContentWrapper,
  HeadlineSmall,
  Image,
  RichTextContent,
} from '../../content/components';

const wordWrapStyles = css`
  overflow: hidden;
`;

const eventHeadingStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const eventNumMarginStyles = css`
  margin-right: ${theme.spacing.x3};
`;

const EventListNumber = ({
  extraCss, number, showListNumber,
}) => (
  showListNumber
    ? (
      <div css={extraCss}>
        <ListNumber>
          {number}
        </ListNumber>
      </div>
    )
    : null
);

const Event = ({
  address,
  configuration,
  date,
  description,
  endDate,
  endTime,
  hasPhysicalAddress,
  image,
  index,
  showListNumber,
  time,
  title,
  venue,
}) => (
  <ContentWrapper
    extraCss={
      [
        contentStyles.relative,
        contentStyles.mx0Md,
      ]
    }
    imageAlignment={configuration.imageAlignment}
    textAlignment={configuration.textAlignment}
  >

    <Image
      image={image}
      imageAlignment={configuration.imageAlignment}
      imageSize={configuration.imageSize}
    />

    <ContentText
      extraCss={
        [
          contentStyles.flex,
          contentStyles.flexRow,
        ]
      }
      textAlignment={configuration.textAlignment}
    >
      <div
        css={
          [
            image ? contentStyles.flex5 : contentStyles.flex9,
            wordWrapStyles,
          ]
        }
      >
        <div
          css={eventHeadingStyles}
          data-cy="subheading"
        >
          <EventListNumber
            extraCss={eventNumMarginStyles}
            number={index + 1}
            showListNumber={showListNumber}
          />
          <HeadlineSmall>
            {title}
          </HeadlineSmall>
        </div>
        <div data-cy="body">
          <EventDetail
            address={address}
            date={date}
            endDate={endDate}
            endTime={endTime}
            time={time}
            venue={venue}
          />

          {
            description
            && (
              <RichTextContent
                value={description}
              />
            )
          }

          {
            hasPhysicalAddress
            && (
              <ActionLink
                target="_blank"
                text="Get Directions"
                url={
                  'https://www.google.com/maps/dir/?api=1&destination='.concat(encodeURIComponent(address))
                }
              />
            )
          }
        </div>
      </div>
    </ContentText>
  </ContentWrapper>
);

Event.propTypes = {
  address: string.isRequired,
  configuration: contentConfiguration.configurationPropTypes,
  date: string.isRequired,
  description: array,
  endDate: string.isRequired,
  endTime: string.isRequired,
  hasPhysicalAddress: bool.isRequired,
  showListNumber: bool,
  time: string.isRequired,
  title: string.isRequired,
  venue: string.isRequired,
};

export default Event;
