import { css } from '@emotion/react';
import {
  Alert, Drawer, MintedField, TextInput, Checkbox, Textarea, theme, Button, LoadingSpinner, useMediaQueryState,
} from '@minted/minted-components';
import { OneForm } from '@oneform/react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  React, useState, useMemo,
} from 'react';
import { connect, useDispatch } from 'react-redux';

import Track from '../../../core/instrumentation';
import { createComment } from '../../actions';
import { selectSite } from '../../selectors';
import {
  commentValidations, emailValidations, nameValidations,
} from '../../validations';

const validations = {
  authorEmail: emailValidations,
  authorName: nameValidations,
  comment: commentValidations,
};

const CURRENT_USER_KEY = 'currentUser';

const drawerStyles = css`
  ${theme.media.lessThan('medium')`
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
  `};
`;

const commentContainerStyles = css`
  padding-top: ${theme.spacing.x8};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  form {
    width: 80%
  }
`;

const titleStyles = css`
  ${theme.typeStyles.bodyLargeStyles};
  ${theme.typeStyles.bodyBoldStyles};
`;

const errorMessageStyles = css`
  ${theme.typeStyles.bodyExtraSmallStyles};
  ${theme.typeStyles.bodyBoldStyles};
  color: ${theme.colors.negative800};
  padding-top: ${theme.spacing.x6};
`;

const formSpacingStyles = css`
  margin-top: ${theme.spacing.x6};
`;

const NewCommentForm = ({
  closeDrawer, isWritingComment, replyId, siteId,
}) => {
  const dispatch = useDispatch();
  const [
    formErrors,
    setFormErrors,
  ] = useState({});
  const [
    isSaving,
    setIsSaving,
  ] = useState(false);
  const [
    comment,
    setComment,
  ] = useState({});

  const onClose = () => {
    setFormErrors({});
    setComment({});
    closeDrawer();
  };

  const [
    currentUser,
    setCurrentUser,
  ] = useState(typeof localStorage === 'undefined' ? null : JSON.parse(localStorage.getItem(CURRENT_USER_KEY)));

  const onFormSubmit = (event) => {
    setIsSaving(true);

    const comment = {
      ...event.registeredValues,
      siteId,
    };

    if (replyId) {
      comment.parentComment = replyId;
    }
    setComment(comment);

    localStorage.setItem(CURRENT_USER_KEY, JSON.stringify({
      email: comment.authorEmail,
      name: comment.authorName,
    }));
    setCurrentUser({
      email: comment.authorEmail,
      name: comment.authorName,
    });

    dispatch(createComment(comment)).then(() => {
      onClose();
      Track.emit({
        event: 'guest_post_comment',
        feature: 'holiday_website',
        site_id: siteId,
      });
    }).catch(setFormErrors).finally(() => {
      setIsSaving(false);
    });
  };

  const extraSmallMediaQueryState = useMediaQueryState({
    mediaQuerySize: 'extraSmall',
  });

  const responsiveInputProps = useMemo(
    () => ({
      emailPlaceholder: (
        extraSmallMediaQueryState === 'ABOVE'
          ? ''
          : 'Your email address'
      ),
      namePlaceholder: (
        extraSmallMediaQueryState === 'ABOVE'
          ? ''
          : 'Your name'
      ),
      textInputSize: (
        extraSmallMediaQueryState === 'ABOVE'
          ? 'small'
          : 'medium'
      ),
    }),
    [
      extraSmallMediaQueryState,
    ]
  );

  return (
    <Drawer
      css={drawerStyles}
      fullscreen
      onClose={onClose}
      onOverlayClick={onClose}
      show={isWritingComment}
    >
      <div css={commentContainerStyles}>
        <div css={titleStyles}>
          Write a comment
        </div>
        <LoadingSpinner
          alignment="center"
          loading={isSaving}
          variation="dark"
        />
        <OneForm
          onSubmit={onFormSubmit}
          validations={validations}
          values={
            {
              ...comment,
              authorEmail: currentUser?.email || '',
              authorName: currentUser?.name || '',
              emailAuthorOnReplies: true,
              isSubscribed: true,
            }
          }
        >
          <div>
            <div css={formSpacingStyles}>
              <MintedField>
                <Textarea
                  minRows={4}
                  name='comment'
                  placeholder='Write a comment...'
                  size={responsiveInputProps.textInputSize}
                />
              </MintedField>
            </div>
            <div css={formSpacingStyles}>
              <MintedField>
                <TextInput
                  label='Your name'
                  name='authorName'
                  placeholder={responsiveInputProps.namePlaceholder}
                  size={responsiveInputProps.textInputSize}
                />
              </MintedField>
            </div>
            <div css={formSpacingStyles}>
              <MintedField>
                <TextInput
                  label='Your email address'
                  name='authorEmail'
                  placeholder={responsiveInputProps.emailPlaceholder}
                  size={responsiveInputProps.textInputSize}
                />
              </MintedField>
            </div>
            <Alert
              secondaryText="Your email won't be displayed."
              show
            />

            <div css={formSpacingStyles}>
              <MintedField isCheckboxElement>
                <Checkbox
                  additionalText='Subscribe to Minted email updates. New subscribers will receive a 20% off welcome offer.'
                  name='isSubscribed'
                  size='small'
                />
              </MintedField>
            </div>
            <div css={formSpacingStyles}>
              <MintedField isCheckboxElement>
                <Checkbox
                  additionalText='Send me emails when someone replies to this comment.'
                  name='emailAuthorOnReplies'
                  size={Checkbox.sizes.small}
                />
              </MintedField>
            </div>
          </div>
          <div css={formSpacingStyles}>
            <Button
              expand
              submit
              text="Post"
              type={Button.types.primary}
            />
          </div>
        </OneForm>
        {
          !isEmpty(formErrors) && (
            <div css={errorMessageStyles}>
              Comment failed to post. Please try again.
            </div>
          )
        }
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  const site = selectSite(state.resources.sites);

  return {
    siteId: site.id,
  };
};

NewCommentForm.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  isWritingComment: PropTypes.bool,
  replyId: PropTypes.number,
  siteId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(NewCommentForm);
