import { DesignComponent } from '@minted/fancyclothes';
import PropTypes, {
  arrayOf, object, shape, string,
} from 'prop-types';
import React from 'react';

import { formatEventDate } from '../../events/utils';
import { smMarginBottomStyles } from '../../neutral/styles/spacing';

const ARROW_HEIGHT = 10;
const POPUP_WIDTH = 252;
const VERTICAL_OFFSET = 55;
const BORDER_COLOR = 'rgba(0,0,0,0.2)';
const BORDER_RADIUS = 2;

const styles = {
  arrow: {
    borderLeft: `${ARROW_HEIGHT}px solid transparent`,
    borderRight: `${ARROW_HEIGHT}px solid transparent`,
    borderTop: `${ARROW_HEIGHT}px solid #ffffff`,
    bottom: `-${ARROW_HEIGHT - 1}px`,
    boxSizing: 'border-box',
    left: '50%',
    marginLeft: `-${ARROW_HEIGHT}px`,
    position: 'absolute',
  },
  arrowBorder: {
    borderLeft: `${ARROW_HEIGHT + 4}px solid transparent`,
    borderRight: `${ARROW_HEIGHT + 4}px solid transparent`,
    borderTop: `${ARROW_HEIGHT + 4}px solid ${BORDER_COLOR}`,
    bottom: `-${ARROW_HEIGHT}px`,
    boxShadow: 'rgb(0,0,0,0.3) 0px 1px 4px -1px',
    boxSizing: 'border-box',
    left: '50%',
    marginLeft: `-${ARROW_HEIGHT + 4}px`,
    position: 'absolute',
  },
  box: {
    bottom: VERTICAL_OFFSET,
    boxSizing: 'border-box',
    cursor: 'default',
    left: `-${POPUP_WIDTH / 2}px`,
    position: 'absolute',
    width: POPUP_WIDTH + 'px',
    zIndex: 10,
  },
  boxBorder: {
    backgroundColor: BORDER_COLOR,
    borderRadius: BORDER_RADIUS + 'px',
    bottom: 0,
    boxShadow: 'rgba(0,0,0,0.3) 0px 1px 4px -1px',
    boxSizing: 'border-box',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: POPUP_WIDTH + 'px',
  },
  close: {
    color: '#999999',
    cursor: 'pointer',
    fontSize: '22px',
    fontWeight: 300,
    lineHeight: 1,
    position: 'absolute',
    right: '8px',
    top: '2px',
  },
  content: {
    backgroundColor: '#ffffff',
    borderRadius: BORDER_RADIUS + 'px',
    boxSizing: 'border-box',
    color: '#555555',
    margin: '1px',
    overflowWrap: 'break-word',
    padding: '10px 15px',
    position: 'relative',
    width: (POPUP_WIDTH - 2) + 'px',
  },
};

class InfoPopup extends DesignComponent {
  render() {
    const {
      events, onCloseClick,
    } = this.props;
    const textStyles = {
      body: {
        ...this.getFontStyles({
          lineHeight: 1.6,
        }, [
          'DesignProvider',
        ]),
        ...this.getFontSizeStyles(12, {}, [
          'DesignProvider',
        ]),
      },
      title: {
        ...this.getFontStyles({
          lineHeight: 1.2,
        }, [
          'SmallHeadline',
          'DesignProvider',
        ]),
        ...this.getFontSizeStyles(14, {}, [
          'SmallHeadline',
          'DesignProvider',
        ]),
      },
    };

    return (
      <div css={styles.box}>
        <div css={styles.boxBorder}></div>
        <div css={styles.arrowBorder}></div>
        {
          events.map((event, index) => (
            <div
              css={styles.content}
              key={index}
            >
              <div
                css={
                  [
                    textStyles.title,
                    smMarginBottomStyles,
                  ]
                }
              >
                {event.title}
              </div>
              <div css={textStyles.body}>
                {event.time}
                ,
                {' '}
                {formatEventDate(event.date)}
                <br />
                {event.venue}
                <br />
                {event.address}
              </div>
            </div>
          ))
        }
        <div
          css={styles.close}
          onClick={onCloseClick}
        >
          ×
        </div>
        <div css={styles.arrow}></div>
      </div>
    );
  }
}

InfoPopup.propTypes = {
  events: arrayOf(
    shape({
      address1: string.isRequired,
      address2: string,
      city: string.isRequired,
      date: string.isRequired,
      description: object,
      state: string.isRequired,
      time: string.isRequired,
      title: string.isRequired,
      venue: string.isRequired,
      zipCode: string.isRequired,
    }).isRequired
  ).isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default InfoPopup;
