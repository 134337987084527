const initialState = {
  props: null,
  type: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        props: action.payload.props,
        type: action.payload.modalType,
      };
    case 'CLOSE_MODAL':
      return initialState;
    default:
      return state;
  }
};
