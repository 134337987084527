const initialState = {
  focusedLocationIndex: null,
};

export default (state = initialState, action) => {
  if (action.type === 'FOCUS_EVENT_LOCATION') {
    return {
      focusedLocationIndex: action.payload,
    };
  }

  return state;
};
