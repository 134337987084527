const initialState = [];

const makeKey = (action) => `${action.meta.model}-${action.meta.id}`;

const hasKey = (action) => typeof action.meta.key !== 'undefined';

export default (state = initialState, action) => {
  let key;

  switch (action.type) {
    case 'CUSTOM_API_REQUEST_PENDING':
    case 'DELETE_RESOURCE_PENDING':
    case 'UPDATE_RESOURCE_PENDING':
    case 'CREATE_RESOURCE_PENDING':
      key = hasKey(action) ? action.meta.key : makeKey(action);

      return [
        ...state,
        key,
      ];
    case 'CUSTOM_API_REQUEST_REJECTED':
    case 'DELETE_RESOURCE_REJECTED':
    case 'UPDATE_RESOURCE_REJECTED':
    case 'CREATE_RESOURCE_REJECTED':
    case 'CUSTOM_API_REQUEST_FULFILLED':
    case 'DELETE_RESOURCE_FULFILLED':
    case 'UPDATE_RESOURCE_FULFILLED':
    case 'CREATE_RESOURCE_FULFILLED':
      key = hasKey(action) ? action.meta.key : makeKey(action);

      return state.filter((stateKey) => stateKey !== key);
    default:
      return state;
  }
};
