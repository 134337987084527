import { css } from '@emotion/react';
import {
  TextLink, Icon, theme,
} from '@minted/minted-components';
import { Fragment, React } from 'react';

import Comment from './Comment';
import { SIMPLIFIED_WEBSITE_CONTENT_WIDTH } from '../../constants';

const commentCardStyles = css`
    background-color: ${theme.colors.gray100};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: ${theme.spacing.x2};
    max-width: ${SIMPLIFIED_WEBSITE_CONTENT_WIDTH};
    padding-bottom: ${theme.spacing.x8};
    padding-top: ${theme.spacing.x4};
    width: 100%;
`;

const replyButtonContainerStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: ${theme.spacing.x6};
  padding-right: ${theme.spacing.x6};
`;

export default ({
  comments,
  isDeleteEnabled,
  isReadOnly,
  startReplyComment,
}) => (
  <Fragment>
    {
      comments.map((comment, index) => (
        <div
          css={commentCardStyles}
          key={index}
        >
          <Comment
            comment={comment}
            isDeleteEnabled={isDeleteEnabled}
            isReadOnly={isReadOnly}
            key={comment.id}
          />
          {
            comment.replies?.map((reply) => (
              <Comment
                comment={reply}
                isDeleteEnabled={isDeleteEnabled}
                isReadOnly={isReadOnly}
                key={reply.id}
              />
            ))
          }
          {
            !isReadOnly && (
              <div css={replyButtonContainerStyles}>
                <TextLink
                  icon={Icon.types.reply}
                  iconAlignment={TextLink.textLinkIconAlignments.left}
                  onClick={startReplyComment(comment.id)}
                  size={TextLink.textLinkSizes.small}
                  text="Reply"
                  type={TextLink.textLinkTypes.primary}
                />
              </div>
            )
          }
        </div>
      ))
    }
  </Fragment>
);
