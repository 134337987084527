import ResizeObserver from 'resize-observer-polyfill';

const consumeEvent = (event) => {
  if (event.stopPropagation) {
    event.stopPropagation();
  }
  if (event.cancelBubble) {
    event.cancelBubble = true;
  }
};

const waitForEvent = (type, capture) => new Promise((resolve) => {
  const watcher = (event) => {
    resolve(event);
    window.removeEventListener(type, watcher, capture);
  };

  window.addEventListener(type, watcher, capture);
});

export default (target, scroller, onUserScrollCallback) => {
  const handleResize = () => {
    scroller.willRefNeedScroll(target, 'pages').then(({
      needsScroll, performScroll,
    }) => {
      if (needsScroll) {
        waitForEvent('scroll', true).then(consumeEvent);
        performScroll();
      }
    });
  };

  const ro = new ResizeObserver(handleResize);
  let isRunning = true;

  const handleScroll = (event) => {
    disable();

    if (typeof onUserScrollCallback === 'function') {
      onUserScrollCallback();
    }
  };

  const enable = () => {
    if (isRunning) {
      ro.observe(document.body);
      window.addEventListener('scroll', handleScroll);
    }
  };

  const disable = () => {
    if (!isRunning) {
      return;
    }

    isRunning = false;

    ro.unobserve(document.body);
    window.removeEventListener('scroll', handleScroll);
  };

  scroller.willRefNeedScroll(target, 'pages').then(({
    needsScroll, performScroll,
  }) => {
    if (needsScroll) {
      // Scroll page and enable viewport lock after scroll is complete
      // This ensures that other components are able to respond to the initial scroll
      waitForEvent('scroll').then(enable);
      performScroll();
    } else {
      // If we won't need to scroll then we don't want to wait for an event
      enable();
    }
  });

  return disable;
};
