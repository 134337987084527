import { ActionLink } from '@minted/fancyclothes';
import {
  array, func, shape, string,
} from 'prop-types';
import React from 'react';

import {
  ContentText, ContentWrapper, HeadlineSmall, Image, RichTextContent,
} from './components';
import { configurationPropTypes } from './configuration';

export const Content = ({
  actionLinkProps, configuration, description, image, title, url,
}) => (
  <ContentWrapper
    imageAlignment={configuration.imageAlignment}
    textAlignment={configuration.textAlignment}
  >
    <Image
      image={image}
      imageAlignment={configuration.imageAlignment}
      imageSize={configuration.imageSize}
    />
    <ContentText textAlignment={configuration.textAlignment}>
      <div data-cy="subheading">
        <HeadlineSmall>
          {title}
        </HeadlineSmall>
      </div>
      <div data-cy="body">
        <RichTextContent
          value={description}
        />
        {
          url && actionLinkProps && (
            <ActionLink
              target="_blank"
              url={url}
              {...actionLinkProps}
            />
          )
        }
      </div>
    </ContentText>
  </ContentWrapper>
);

Content.propTypes = {
  actionLinkProps: shape({
    onClick: func.isRequired,
    text: string.isRequired,
  }),
  configuration: configurationPropTypes,
  description: array,
  image: string,
  title: string.isRequired,
  url: string,
};
