import throttle from 'lodash/throttle';
import ReactDOM from 'react-dom';

import { getOffsetTop, getPageYOffset } from '../../core/windowUtils';

const INTERVAL = 1000 / 30;

export default class ActivePageMonitor {
  constructor(refManager, currentRouteName, navigateTo) {
    this.manager = refManager;
    this.currentRouteName = currentRouteName;
    this.navigateTo = navigateTo;

    this.onScroll = this.onScroll.bind(this);
    this.onRefsChange = this.onRefsChange.bind(this);
    this.throttledOnScroll = throttle(this.onScroll, INTERVAL);
    this.sections = [];
    this.currentSection = null;
  }

  enable() {
    this.onRefsChange();
    this.addListeners();
  }

  disable() {
    this.removeListeners();
  }

  addListeners() {
    this.manager.addListener(this.onRefsChange);
    window.addEventListener('scroll', this.throttledOnScroll);
  }

  removeListeners() {
    this.manager.removeListener(this.onRefsChange);
    window.removeEventListener('scroll', this.throttledOnScroll);
  }

  onScroll() {
    const section = this.getCurrentSection();

    if (section !== this.currentSection) {
      this.currentSection = section;
      this.updateRoute();
    }
  }

  updateRoute() {
    if (this.currentSection) { // null when deleting site
      const name = this.currentSection.slug;

      if (name !== this.currentRouteName) {
        this.currentRouteName = name;

        // Replace the current history state so that scrolling down the page doesn't generate a ton of previous states
        this.navigateTo(name, {}, {
          replace: true,
        });
      }
    }
  }

  onRefsChange() {
    const refs = this.manager.getRefs();
    const pages = refs.pages || {};

    // If any refs are null it means that the pages are re-rendering. Once they're done we'll get valid refs.
    if (Object.entries(pages).every(([
      slug,
      ref,
    ]) => ref != null)) {
      this.updateSections(pages);
    }
  }

  updateSections(refs) {
    this.sections = Object.entries(refs)
      .map(([
        slug,
        ref,
      ]) => (
        {
          element: ReactDOM.findDOMNode(ref),
          slug,
        }
      ))
      .sort((sectionA, sectionB) => getOffsetTop(sectionA.element) - getOffsetTop(sectionB.element));
  }

  getScrollPosition() {
    return Math.max(getPageYOffset(), 0);
  }

  getCurrentSection() {
    const scrollPosition = this.getScrollPosition();
    let index = this.sections.length;

    while (index--) {
      const section = this.sections[index];

      if (getOffsetTop(section.element) <= scrollPosition) {
        return section;
      }
    }
  }
}
