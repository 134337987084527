import { css } from '@emotion/react';
import { SmallHeadline, TinyHeadline } from '@minted/fancyclothes';
import React from 'react';

import {
  imageAlignmentMap, imageSizeMap, imageSpacingMap, textAlignmentMap,
} from './configuration';
import {
  bottomSpacerOnly, flex, flex1, m20,
} from './styles';
import ThemeExtractor from '../core/components/ThemeExtractor';
import RichTextRenderer from '../forms/components/RichText/RichTextRenderer';
import { lgMarginBottomStyles } from '../neutral/styles/spacing';
import Photo from '../pages/website/Photo';

export const ContentText = ({
  children, extraCss = [], textAlignment,
}) => (
  <div
    css={
      [
        flex1,
        textAlignmentMap.get(textAlignment),
        ...extraCss,
      ]
    }
  >
    {children}
  </div>
);

export const ContentWrapper = ({
  children, extraCss = [], imageAlignment, textAlignment,
}) => (
  <div
    css={
      [
        m20,
        lgMarginBottomStyles,
        flex,
        imageAlignmentMap.get(imageAlignment),
        textAlignmentMap.get(textAlignment),
        ...extraCss,
      ]
    }
  >
    {children}
  </div>
);

export const HeadlineSmall = ({
  children,
}) => (
  <div
    css={
      [
        bottomSpacerOnly,
      ]
    }
  >
    <SmallHeadline>
      {children}
    </SmallHeadline>
  </div>
);

export const HeadlineTiny = ({
  children,
}) => (
  <div
    css={
      [
        bottomSpacerOnly,
      ]
    }
  >
    <TinyHeadline>
      {children}
    </TinyHeadline>
  </div>
);

export const Image = ({
  image, imageAlignment, imageSize, textAlignment,
}) => (
  image
    ? (
      <div
        css={
          [
            imageSpacingMap.get(imageAlignment),
            imageSizeMap(imageSize),
            textAlignmentMap.get(textAlignment),
          ]
        }
      >
        <Photo url={image} />
      </div>
    )
    : null
);

export const RichTextContent = ({
  value,
}) => (
  Boolean(value)
    ? (
      <ThemeExtractor
        rules={
          [
            'linkColor',
          ]
        }
      >
        {
          ({
            linkColor,
          }) => (
            <div
              css={
                css`
                a {
                  color: ${linkColor};
                }
              `
              }
            >
              <RichTextRenderer
                value={value}
              />
            </div>
          )
        }
      </ThemeExtractor>
    )
    : null
);
