import config from 'config';
import isEmpty from 'lodash/isEmpty';

import { fetchCurrentSite } from './utils';
import API from '../core/api';
import { GUESTS_TOKEN_EXPIRE_TIME_SECONDS } from '../core/constants';
import {
  clearChanges,
  commitChanges,
  fetchResource,
  stageChanges,
  createResource,
} from '../resources/actions';

const model = 'sites';

const resourceFulfilled = (model, data, params) => ({
  meta: {
    model,
    params: params || {},
    time: Date.now(),
  },
  payload: data,
  type: 'FETCH_RESOURCE_FULFILLED',
});

export const doUpgrade = () => (dispatch) => {
  dispatch({
    type: 'SITE_DO_UPGRADE',
  });
};

export const doPurchaseCustomDomain = () => (dispatch) => {
  dispatch({
    type: 'SITE_DO_PURCHASE_CUSTOM_DOMAIN',
  });
};

export const doDomainRenew = () => (dispatch) => {
  dispatch({
    type: 'SITE_DO_DOMAIN_RENEW',
  });
};

export const loadSiteData = (siteData) => (dispatch) => {
  // When the data arrives from the API sections are nested within pages. The
  // resource system depends on sections being their own resource so we
  // extract them here.
  const sections = [];

  if (siteData.pages) {
    siteData.pages.filter((page) => !isEmpty(page.sections)).forEach((page) => {
      sections.push([
        page.id,
        page.sections,
      ]);
      page.sections = page.sections.map(({
        id,
      }) => id);
    });
  }
  const actions = Object.entries(siteData)
    .filter(([
      key,
      value,
    ]) => !isEmpty(value))
    .map(([
      key,
      value,
    ]) => dispatch(resourceFulfilled(key, value)));

  sections.forEach(([
    page,
    data,
  ]) => {
    actions.push(dispatch(resourceFulfilled('sections', data, {
      page,
    })));
  });

  return Promise.all(actions);
};

export const updateSite = (id, data) => {
  const patchFields = Object.keys(data);

  return (dispatch) => {
    dispatch(stageChanges('sites', id, data, {
      patch: true,
    }));

    return (
      dispatch(
        commitChanges(
          'sites',
          id,
          undefined,
          {},
          [
            'themeDataOverrides',
          ],
          patchFields,
        )
      )
        .finally(() => dispatch(clearChanges('sites', id)))
    );
  };
};

export const setSiteAsset = (id, key, value) => (dispatch) => API
  .post(`sites/${id}/set_asset`, {}, {
    key,
    value,
  })
  .then((site) => {
    // FIXME: support updating resource store with data outside
    // of the normal updateResource action/reducer

    dispatch(fetchSite(id));

    return site;
  });

export const fetchSite = (id) => {
  const siteId = Boolean(id) ? id : window.location.pathname.match(/^\/site\/(\d+)/)[1];

  return (
    fetchResource(
      'sites',
      'sites',
      siteId && {
        id: siteId,
      },
      {},
      [
        'themeDataOverrides',
      ],
    )
  );
};

export const deleteSite = (id) => (dispatch) => API
  .delete(`sites/${id}`, {})
  .then(() => {
    window.location.reload();
  });

export const fetchImageUploads = () => fetchResource('image-uploads', 'image-uploads');

export const requireGuestAuthenticate = () => (dispatch) => {
  dispatch({
    type: 'REQUIRE_GUEST_AUTHENTICATE',
  });
};

export const getGuestTokenCookie = () => ('; ' + document.cookie).split(`; ${config.get('guestTokenCookieName')}=`).pop().split(';')[0];

export const fetchGuestToken = () => API.post('login/guest/token', {
  basePath: '',
}).then((data) => {
  document.cookie = `${config.get('guestTokenCookieName')}=${data.token};max-age=${GUESTS_TOKEN_EXPIRE_TIME_SECONDS}`;
});

export const authenticateGuest = (password) => (dispatch) => dispatch({
  payload: {
    promise: new Promise((resolve, reject) => {
      const hasToken = Boolean(getGuestTokenCookie());
      const tokenPromises = [];

      if (!hasToken) {
        tokenPromises.push(fetchGuestToken());
      }

      // If the promise exists, we need to wait for it, so .all will have either 0 or 1 promises.
      Promise.all(tokenPromises).then(() => {
        const guestToken = getGuestTokenCookie();
        const headers = {
          Authorization: `Guest ${guestToken}`,
        };

        API.post('sites/authenticate/', {
          headers,
        }, {
          password,
        }).then((response) => {
          fetchCurrentSite({
            headers,
          }).then((data) => {
            dispatch(loadSiteData(data)).then(() => {
              resolve(data);
            });
          });
        }, (error) => {
          reject(error);
        });
      });
    }),
  },
  type: 'AUTHENTICATE_GUEST',
});

export const createComment = (comment) => (dispatch) => dispatch(createResource('comments', comment)).then(() => {
  dispatch(fetchResource('comments', '/comments'));
});

export const deleteComment = (id) => (dispatch) => API
  .delete(`comments/${id}/`, {})
  .then((site) => {
    dispatch(fetchResource('comments', '/comments'));
  });

export const updateNotificationSetting = (notificationSetting, siteId) => (dispatch) => dispatch({
  payload: {
    promise: new Promise((resolve, reject) => {
      // stage change for optimistic update
      dispatch(stageChanges('sites', siteId, {
        notificationSettings: notificationSetting,
      }, {
        patch: true,
      }));
      API.put('notification-settings', {}, notificationSetting)
        .then(() => {
          dispatch(fetchSite(siteId)).then(() => {
            dispatch(clearChanges('sites', siteId, 'notificationSettings'));
          }).then(resolve);
        }).catch((error) => {
          dispatch(clearChanges('sites', siteId, 'notificationSettings'));
          reject(error.response.body);
        });
    }),
  },
  type: 'UPDATE_NOTIFICATION_SETTING',
});

export const setUpdateSiteErrors = ({
  errors, id,
}) => ({
  meta: {
    id,
    model: 'sites',
  },
  payload: errors,
  type: 'COMMIT_RESOURCE_UPDATE_REJECTED',
});

export default {
  clearChanges: (id, patchFields) => clearChanges(model, id, patchFields),
  commitChanges: (id, patchFields, transform) => (
    commitChanges(
      model,
      id,
      transform,
      {},
      [
        'themeDataOverrides',
      ],
      patchFields,
    )
  ),
  delete: deleteSite,
  fetch: fetchSite,
  stageChanges: (id, data, options) => (
    stageChanges(
      model,
      id,
      data,
      options,
    )
  ),
};
