import API from '../core/api';
import {
  clearChanges,
  clearCreation,
  clearRemoval,
  commitChanges,
  commitCreation,
  commitRemoval,
  fetchResource,
  invalidateCollections,
  reorderResource,
  stageChanges,
  stageCreation,
  stageRemoval,
} from '../resources/actions';

export const focusLocation = (index) => ({
  payload: index,
  type: 'FOCUS_EVENT_LOCATION',
});

// TODO: refactor with standardized actions

const model = 'events';

const transformCommit = ({
  address1, address2, ...rest
}) => ({
  address_1: address1,
  address_2: address2,
  ...rest,
});

const _commitCreation = (params, isSaving, setIsSaving) => (dispatch, getState) => {
  if (!isSaving) {
    setIsSaving(true);

    return dispatch(commitCreation(model, params, transformCommit, {}, false)).then((result) => {
      setIsSaving(false);
      const store = getState();

      if (store.resources.invites.collections.length > 0) {
        dispatch(invalidateCollections('invites'));
      }

      if (store.resources.guests.collections.length > 0) {
        dispatch(invalidateCollections('guests'));
      }
    }).catch((err) => setIsSaving(false));
  }
};

const _commitChanges = (id) => (dispatch, getState) => {
  dispatch(commitChanges(model, id, transformCommit)).then((result) => {
    const store = getState();

    if (store.resources.invites.collections.length > 0) {
      dispatch(invalidateCollections('invites'));
    }

    if (store.resources.guests.collections.length > 0) {
      dispatch(invalidateCollections('guests'));
    }
  });
};

const deleteRsvps = (eventIds) => (dispatch) => (
  API.delete(
    'events/delete-rsvps',
    {
      events: eventIds,
    },
  ).then((result) => {
    dispatch(invalidateCollections('rsvps'));
  })
);

const _commitRemoval = (id) => (dispatch) => {
  // Event has been deleted, we need to refresh guest data
  dispatch(commitRemoval(model, model, id, null, null)).then((result) => {
    dispatch(invalidateCollections('guests'));
    dispatch(invalidateCollections('invites'));
    dispatch(invalidateCollections('rsvps'));
  });
};

export default {
  clearChanges: (id) => clearChanges(model, id),
  clearCreation: (params) => clearCreation(model, params),
  clearRemoval: (id) => clearRemoval(model, id),
  commitChanges: _commitChanges,
  commitCreation: _commitCreation,
  commitRemoval: _commitRemoval,
  deleteRsvps,
  fetch: () => fetchResource(model, model),
  reorder: (data) => reorderResource(model, data),
  stageChanges: (id, data) => stageChanges(model, id, data),
  stageCreation: (params, data) => stageCreation(model, data, params),
  stageRemoval: (id) => stageRemoval(model, id),
};
