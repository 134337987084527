export const openModal = (modalType, props) => ({
  payload: {
    modalType,
    props,
  },
  type: 'OPEN_MODAL',
});

export const closeModal = () => ({
  payload: {},
  type: 'CLOSE_MODAL',
});
