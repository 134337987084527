import { css } from '@emotion/react';
import { LargeDivider } from '@minted/fancyclothes';
import { styleUtils } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React from 'react';

import PageTitle from './PageTitle';

const centeredTextStyles = css`
  text-align: center;
`;

const marginBottomStyles = css`
  margin-bottom: ${styleUtils.rem(80)};
`;

const paddingTopStyles = css`
  padding-top: ${styleUtils.rem(80)};
`;

const Page = ({
  children, id, title,
}) => (
  <div
    css={paddingTopStyles}
    id={id}
  >
    <div css={centeredTextStyles}>
      <PageTitle hideDivider={id === 'registry'}>
        {title}
      </PageTitle>
    </div>
    <div css={marginBottomStyles}>
      {children}
    </div>
    <LargeDivider />
  </div>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Page;
