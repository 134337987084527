import { css } from '@emotion/react';
import React from 'react';
import { useSelected } from 'slate-react';

const LinkComponent = ({
  attributes, children, element,
}) => {
  const selected = useSelected();

  return (
    <a
      {...attributes}
      className={
        selected
          ? css`
              box-shadow: 0 0 0 3px #ddd;
            `
          : ''
      }
      href={element.url}
      rel="noreferrer"
      target='_blank'
    >
      {children}
    </a>
  );
};

export const Element = (props) => {
  const {
    attributes, children, element,
  } = props;
  const style = {
    textAlign: element.align,
  };

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote
          style={style}
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul
          style={
            {
              ...style,
              listStylePosition: 'inside',
              paddingInlineStart: '0px',
            }
          }
          {...attributes}
        >
          {children}
        </ul>
      );
    case 'numbered-list':
      return (
        <ol
          style={
            {
              ...style,
              listStylePosition: 'inside',
              paddingInlineStart: '0px',
            }
          }
          {...attributes}
        >
          {children}
        </ol>
      );
    case 'heading-one':
      return (
        <h1
          style={style}
          {...attributes}
        >
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2
          style={style}
          {...attributes}
        >
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li
          style={style}
          {...attributes}
        >
          {children}
        </li>
      );
    case 'link':
      return <LinkComponent {...props} />;
    default:
      return (
        <p
          style={style}
          {...attributes}
        >
          {children}
        </p>
      );
  }
};

export const Leaf = ({
  attributes, children, leaf,
}) => {
  if (leaf.bold) {
    children = (
      <strong>
        {children}
      </strong>
    );
  }

  if (leaf.code) {
    children = (
      <code>
        {children}
      </code>
    );
  }

  if (leaf.italic) {
    children = (
      <em>
        {children}
      </em>
    );
  }

  if (leaf.underline) {
    children = (
      <u>
        {children}
      </u>
    );
  }

  return (
    <span {...attributes}>
      {children}
    </span>
  );
};
