import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  WrapperLarge,
  WrapperXLarge,
} from '../../layout/website';
import Page from '../../pages/website/Page';
import ContentSections from '../../website/components/ContentSections';

class Photos extends Component {
  render() {
    const {
      id,
      isMultiPage,
      pageId,
      title,
    } = this.props;

    const Wrapper = isMultiPage ? WrapperXLarge : WrapperLarge;

    return (
      <Page
        id={id}
        title={title}
      >
        <Wrapper>
          <ContentSections pageId={pageId} />
        </Wrapper>
      </Page>
    );
  }
}

Photos.propTypes = {
  id: PropTypes.string.isRequired,
  isMultiPage: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default Photos;
