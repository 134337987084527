import { css } from '@emotion/react';
import { Button as FancyButton } from '@minted/fancyclothes';
import {
  Button, TextInput, theme, Modal, ModalHeader, ModalBody, Divider, TextLink, useMediaQueryState,
} from '@minted/minted-components';
import React, {
  useCallback, useState, useMemo,
} from 'react';

import { ContentText, HeadlineSmall } from '../../../content/components';
import API from '../../../core/api';
import { getGuestTokenCookie } from '../../../website/actions';

// Event response data structure
// {
//    eventId: 1,
//    eventResponseData: [
//      {
//       guestId: 1,
//       responseType: '',
//       answers: [
//         {
//           question_id: 1,
//           answer: '',
//         },
//       ],
//     },
//  ],
// }

// Guest name update structure
// {
//   guestId: 1,
//   firstName: '',
//   lastName: '',
// },

const emptyFormState = {
  addressbookContactId: '',
  eventResponseData: [],
  updated_guest_names: [],
};

const rsvpFormStyles = {
  button: css`
    width: 25%;
  `,
  familyName: css`
    ${theme.typeStyles.bodySmallLineHeight};
    ${theme.typeStyles.bodyBoldStyles};
  `,
  nameInput: css`
    margin-bottom: ${theme.spacing.x4};
  `,
  rsvpFamily: css`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing.x6};
    margin-bottom: ${theme.spacing.x6};
  `,
  searchBar: css`
    width: 75%;
  `,
  searchBarMobile: css`
    width: 90%;
  `,
  searchContent: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  textLink: css`
    display: flex;
  `,
  wrapper: css`
  `,
};

const RSVPForm = ({
  configuration,
  isPreview,
}) => {
  const [
    searchTerm,
    setSearchTerm,
  ] = useState('');
  const [
    formData,
    setFormData,
  ] = useState(emptyFormState);
  const [
    contact,
    setContact,
  ] = useState({});
  const [
    events,
    setEvents,
  ] = useState([]);
  const [
    guests,
    setGuests,
  ] = useState([]);
  const [
    currentEventIndex,
    setCurrentEventIndex,
  ] = useState(0);

  const [
    searchResults,
    setSearchResults,
  ] = useState([]);
  const [
    showAdditionsRSVP,
    setShowAdditionsRSVP,
  ] = useState(false);
  const [
    showResults,
    setShowResults,
  ] = useState(true);
  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: 'medium',
  });
  const isMobile = mediumMediaQueryState === 'BELOW';

  const resetState = useCallback(() => {
    setSearchTerm('');
    setFormData(emptyFormState);
    setContact({});
    setEvents([]);
    setGuests([]);
    setCurrentEventIndex(0);
    setSearchResults([]);
    setShowAdditionsRSVP(false);
    setShowResults(true);
  }, []);

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);
  const closeModal = useCallback(() => {
    resetState();
    setIsModalOpen(false);
  }, [
    resetState,
  ]);

  const getInvitesForContact = useCallback((addressbookContactId) => {
    // Contact ID from search form is passed in here, and invites are queried
    // Araceli Acevedo is 'f02b1e4a-b21a-4a40-8e4c-ea588d23b9af' for local dev
    API.get('guest-management/rsvp-form-data', {}, {
      addressbook_contact_id: addressbookContactId,
    }).then((formData) => {
      setEvents(formData.events.toSorted((event1, event2) => event1.position - event2.position));
      setGuests(formData.guests);
      setContact(formData.contact);
      setIsModalOpen(false);
    });
  }, []);

  const search = (event) => {
    event.preventDefault();
    const guestToken = getGuestTokenCookie();
    const headers = {
      Authorization: `Guest ${guestToken}`,
    };

    API.get('guest-management/search', {
      headers,
    }, {
      q: searchTerm,
    }).then((response) => {
      setSearchResults(response);
      setShowResults(true);
      setShowAdditionsRSVP(false);
      setSearchTerm('');
    }).finally(()=> {
      if (!isPreview) {
        setIsModalOpen(true);
      }
    });
  };

  const firstResultRSVPV = useMemo(() => {
    if (searchResults.length > 0) {
      return searchResults[0];
    }

    return null;
  }, [
    searchResults,
  ]);

  const additionalResultsRSVP = useMemo(() => {
    if (searchResults.length > 1) {
      return searchResults.slice(1);
    }

    return [];
  }, [
    searchResults,
  ]);

  const getFormattedGuestNames = (guestNames) => {
    if (guestNames.every((guest) => guest === null)){
      return `Party of ${guestNames.length}`;
    }
    guestNames = guestNames.sort((prev, curr) => (curr ? curr.length : 0) - (prev ? prev.length : 0));

    const unnamedGuestsCount = guestNames.filter((guest) => guest === null).length;

    const areThereUnknownGuests = unnamedGuestsCount > 0;

    const formattedNames = guestNames.reduce((names, guest, index)=> {
      let separator = ', ';

      if (index === 0) {
        separator = '';
      }
      if (index + 1 === guestNames.length && !areThereUnknownGuests){
        separator = ' & ';
      }
      if (guest === null){
        return names;
      }

      return `${names}${separator} ${guest}`;
    }, '');

    if (areThereUnknownGuests) {
      return `${formattedNames} & ${unnamedGuestsCount === 1 ? 'Guest' : `${unnamedGuestsCount} Guests`}`;
    }

    return formattedNames;
  };

  const moveToNextEvent = useCallback(() => {
    setCurrentEventIndex((prevVal) => prevVal + 1);
  }, []);

  return (
    <div css={rsvpFormStyles.wrapper}>
      <Modal
        mobileFullscreen
        onCloseClick={closeModal}
        open={isModalOpen}
        size="medium"
      >
        <ModalHeader>
          Find your RSVP in the list below
        </ModalHeader>
        <ModalBody>
          {
            showResults && (
              <>
                <div>
                  {
                    firstResultRSVPV && (
                      <div css={rsvpFormStyles.rsvpFamily}>
                        <div>
                          <div css={rsvpFormStyles.familyName}>
                            {firstResultRSVPV.partyName}
                          </div>
                          <div>
                            {getFormattedGuestNames(firstResultRSVPV.guests)}
                          </div>
                        </div>
                        <div css={rsvpFormStyles.button}>
                          <Button
                            expand
                            onClick={()=> getInvitesForContact(firstResultRSVPV)}
                            size={Button.sizes.medium}
                            text="Select"
                          />
                        </div>
                      </div>
                    )
                  }
                </div>
                {
                  showAdditionsRSVP
                    ? (
                      <>
                        <Divider
                          title="OR"
                          type="light"
                        />
                        {
                          additionalResultsRSVP.map((additionalRSVP)=> (
                            <div
                              css={rsvpFormStyles.rsvpFamily}
                              key={additionalRSVP.addressbookContactId}
                            >
                              <div>
                                <div css={rsvpFormStyles.familyName}>
                                  {additionalRSVP.partyName}
                                </div>
                                <div>
                                  {getFormattedGuestNames(additionalRSVP.guests)}
                                </div>
                              </div>
                              <div css={rsvpFormStyles.button}>
                                <Button
                                  expand
                                  onClick={()=> getInvitesForContact(additionalRSVP.addressbookContactId)}
                                  size={Button.sizes.medium}
                                  text="Select"
                                  type={Button.types.secondary}
                                />
                              </div>
                            </div>
                          ))
                        }
                        <div css={rsvpFormStyles.textLink}>
                          <div css={css`margin-right: ${theme.spacing.base};`}>
                            (Still nothing?
                          </div>
                          <TextLink
                            href="#"
                            onClick={() =>  setShowResults(false)}
                            text="Try another search"
                            type="secondaryAlternate"
                          />
                          )
                        </div>

                      </>
                    )
                    : (
                      <>
                        <Divider
                          title=""
                          type="light"
                        />
                        <div css={rsvpFormStyles.textLink}>
                          <div css={css`margin-right: ${theme.spacing.base};`}>
                            Not You?
                          </div>
                          <TextLink
                            href="#"
                            onClick={() =>  setShowAdditionsRSVP(true)}
                            text="Pick a different name."
                            type="secondaryAlternate"
                          />
                        </div>
                      </>
                    )
                }
              </>
            )
          }
          {
            !showResults && (
              <div css={rsvpFormStyles.searchContent}>
                <div css={isMobile ? rsvpFormStyles.searchBarMobile : rsvpFormStyles.searchBar}>
                  <ContentText textAlignment={configuration.textAlignment}>
                    <HeadlineSmall>
                      Enter Your Name to RSVP
                    </HeadlineSmall>
                  </ContentText>
                  <div css={rsvpFormStyles.nameInput}>
                    <TextInput
                      disabled={isPreview}
                      onChange={(event) => setSearchTerm(event.target.value)}
                      size="small"
                      value={searchTerm}
                    />
                  </div>
                  <FancyButton
                    block={true}
                    disabled={searchTerm.length === 0}
                    onClick={
                      search
                    }
                    text="Find RSVP"
                  />
                </div>
              </div>
            )
          }
        </ModalBody>
      </Modal>
      {
        events.length > 0
          ? (
            <>
              <div>
                Contact
                {
                  JSON.stringify(contact)
                }
              </div>
              <div>
                Event
                {
                  JSON.stringify(events[currentEventIndex])
                }
              </div>
              <div>
                Guests
                {
                  JSON.stringify(guests)
                }
              </div>
              <div>
                Form state
                {
                  JSON.stringify(formData)
                }
              </div>
              <Button
                expand
                onClick={moveToNextEvent}
                text="Next"
                type={Button.types.primary}
              />
            </>
          )
          : (
            <>
              <div css={rsvpFormStyles.nameInput}>
                <TextInput
                  disabled={isPreview}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  size="small"
                  value={searchTerm}
                />
              </div>
              <FancyButton
                block={true}
                disabled={searchTerm.length === 0}
                onClick={
                  search
                }
                text="Find RSVP"
              />
            </>
          )
      }
    </div>
  );
};

export default RSVPForm;
