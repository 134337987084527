export const getOffsetTop = (element) => element.offsetTop;

export const getPageYOffset = () => window.pageYOffset;

export const getBoundingClientRect = (element) => element.getBoundingClientRect();

// Chrome seems to use Math.floor internally when using scrollTo and
// scrollIntoView. We need to scroll manually with Math.ceil to
// prevent issues with active page detection.
export const scrollIntoView = (element, offset = 0) => {
  const elRect = getBoundingClientRect(element);
  const bodyRect = getBoundingClientRect(document.body);
  const scrollPos = Math.ceil(elRect.top - bodyRect.top) + offset;

  const frameElement = element.ownerDocument.defaultView.frameElement;

  (
    frameElement
      ? frameElement.parentNode
      : window
  ).scrollTo({
    behavior: 'smooth',
    top: scrollPos,
  });
};

export const scrollToTop = () => {
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  });
};
