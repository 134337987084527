import { array, string } from 'prop-types';
import React from 'react';

import { contentConfiguration } from '../../content';
import {
  ContentText,
  ContentWrapper,
  HeadlineSmall,
  HeadlineTiny,
  Image,
  RichTextContent,
} from '../../content/components';

export const Attendant = ({
  configuration, description, name, photo, title,
}) => (
  <ContentWrapper
    imageAlignment={configuration.imageAlignment}
    textAlignment={configuration.textAlignment}
  >
    <Image
      image={photo}
      imageAlignment={configuration.imageAlignment}
      imageSize={configuration.imageSize}
    />
    <ContentText textAlignment={configuration.textAlignment}>
      <div data-cy="subheading">
        <HeadlineSmall>
          {name}
        </HeadlineSmall>
      </div>
      <div data-cy="body">
        <HeadlineTiny>
          {title}
        </HeadlineTiny>
        <RichTextContent
          value={description}
        />
      </div>
    </ContentText>
  </ContentWrapper>
);

Attendant.propTypes = {
  configuration: contentConfiguration.configurationPropTypes,
  description: array,
  name: string.isRequired,
  photo: string,
  title: string.isRequired,
};

export default Attendant;
