import { css } from '@emotion/react';
import {
  Icon, IconButton, theme,
} from '@minted/minted-components';
import { formatDistanceToNow } from 'date-fns';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from '../../../modals/actions';
import { deleteComment } from '../../actions';

const innerCommentCardStyles = css`
    padding-left: ${theme.spacing.x6};
    padding-right: ${theme.spacing.x6};
`;

const innerCommentCardStylesWithBorder = css`
    border-left: 2px solid ${theme.colors.gray300};
    margin-left: ${theme.spacing.x6};
    padding-left: ${theme.spacing.x6};
    padding-right: ${theme.spacing.x6};
`;

const authorTextStyles = css`
    ${theme.typeStyles.bodyLargeStyles};
    ${theme.typeStyles.bodyBoldStyles};
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: ${theme.spacing.x3};
`;

const commentTextStyles = css`
    ${theme.typeStyles.bodyLargeStyles};
    padding-bottom: ${theme.spacing.x3};
`;

const timestampTextStyles = css`
    ${theme.typeStyles.captionSmallStyles};
    color: ${theme.colors.gray400};
    padding-bottom: ${theme.spacing.x6};
`;

const getTimeStamp = (createdAtDate) => formatDistanceToNow(
  // Calculates time between the createdAt timestamp and now.
  new Date(`${createdAtDate}+00:00`),
  // Using 00:00 as an offset to tell date-fns this is UTC, which will handle converting to local time.
  {
    addSuffix: true,
  }
);

export default ({
  comment,
  isDeleteEnabled,
  isReadOnly,
}) => {
  const dispatch = useDispatch();

  const openDeleteCommentModal = useCallback(
    () => {
      dispatch(
        openModal(
          'DELETE_COMMENT_MODAL',
          {
            isOpen: true,
            onDeleteComment: () => {
              dispatch(deleteComment(comment.id));
            },
          }
        )
      );
    },
    [
      comment.id,
      dispatch,
    ]
  );

  return (
    <div css={comment.parentComment ? innerCommentCardStylesWithBorder : innerCommentCardStyles}>
      <div css={authorTextStyles}>
        {comment.authorName}
        {
          // We need isReadOnly here to prevent button from showing on live site in test/qa.
          isDeleteEnabled && isReadOnly && (
            <IconButton
              iconType={Icon.types.trash}
              onClick={openDeleteCommentModal}
              size={IconButton.sizes.medium}
              type={IconButton.types.destructive}
            />
          )
        }
      </div>
      <div css={commentTextStyles}>
        {comment.comment}
      </div>
      <div css={timestampTextStyles}>
        {getTimeStamp(comment.createdAt)}
      </div>
    </div>
  );
};
