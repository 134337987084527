import ReactDOM from 'react-dom';

import { getBoundingClientRect, scrollIntoView } from '../core/windowUtils';

export default class Scroller {
  constructor(refManager) {
    this.manager = refManager;
  }

  // Scrolls to a ref by id
  scrollTo(id, namespace) {
    // use waitForRef to prevent potential error if the target is re-rendering
    return new Promise((resolve) => {
      this.manager.waitForRef(id, namespace).then((ref) => {
        const element = ReactDOM.findDOMNode(ref);

        if (element) {
          scrollIntoView(element);
          resolve();
        }
      });
    });
  }

  willRefNeedScroll(id, namespace) {
    return new Promise((resolve) => {
      this.manager.waitForRef(id, namespace).then((ref) => {
        const element = ReactDOM.findDOMNode(ref);
        const elRect = getBoundingClientRect(element);
        const bodyRect = getBoundingClientRect(document.body);
        const performScroll = () => scrollIntoView(element);

        resolve({
          needsScroll: elRect.top !== bodyRect.top,
          performScroll,
        });
      });
    });
  }
}
