import { scrollToTop } from '../../core/windowUtils';

export default class MultiPageTransitionBackend {
  enable(currentRoute, isInitialLoad) {
    // Scroll to top when switching to this backend
    if (!isInitialLoad) {
      scrollToTop();
    }
  }

  disable() {}

  transitionTo() {
    scrollToTop();
  }
}
