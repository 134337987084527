import merge from 'lodash/merge';
import { handleActions } from 'redux-actions';

export const initialState = {
  attribution: null,
  colorwayCodeById: {},
  colorways: [],
  dekuraData: {},
  fancyClothesData: {},
  title: null,
};

export default handleActions({
  FETCH_THEME_FULFILLED: (state, {
    payload,
  }) => {
    const dekuraData = payload.data;
    const dekuraTypographyData = (
      dekuraData
      && dekuraData.typography
        ? dekuraData.typography
        : {
          header: {},
          required: {},
        }
    );

    return {
      ...state,
      attribution: payload.design.attribution,
      colorwayCodeById: payload.design.colorways.reduce((accumulator, value) => {
        accumulator[value.external_id] = value.code;

        return accumulator;
      }, {}),
      colorways: payload.design.colorways,
      dekuraData: (
        merge(
          {},
          dekuraData,
          {
            typography: {
              header: {
                CoupleHorizontalNames: (
                  dekuraTypographyData.header?.CoupleHorizontalNames
                  || dekuraTypographyData.required.LargeHeadline
                ),
                CoupleVerticalNames: (
                  dekuraTypographyData.header?.CoupleVerticalNames
                  || dekuraTypographyData.required.LargeHeadline
                ),
                EventDate: (
                  dekuraTypographyData.header?.EventDate
                  || dekuraTypographyData.required.MediumHeadline
                ),
                EventDateHeading: (
                  dekuraTypographyData.header?.EventDateHeading
                  || dekuraTypographyData.required.SmallHeadline
                ),
                IntroMediumHeadline: (
                  dekuraTypographyData.header?.IntroMediumHeadline
                  || dekuraTypographyData.required.MediumHeadline
                ),
                IntroText: (
                  dekuraTypographyData.header?.IntroText
                  || dekuraTypographyData.required.Text
                ),
              },
              required: {
                LargeHeadline: (
                  dekuraTypographyData.required.LargeHeadline
                  || dekuraTypographyData.header?.CoupleHorizontalNames
                  || dekuraTypographyData.header?.CoupleVerticalNames
                ),
              },
            },
          },
        )
      ),
      fancyClothesData: payload.fancyclothes_data,
      title: payload.design.title,
    };
  },
}, initialState);
