import { actions as abkActions } from '@minted/addressbook';
import find from 'lodash/find';
import includes from 'lodash/includes';

import actions from './actions';

const syncResourceActions = [
  'COMMIT_RESOURCE_REMOVAL_FULFILLED',
  'COMMIT_RESOURCE_CREATION_FULFILLED',
  'INVALIDATE_COLLECTIONS',
  'MERGE_RESOURCES',
];
const syncResourceModels = [
  'invites',
  'events',
];

const addressbookMiddleware = ({
  dispatch, getState,
}) => (next) => (action) => {
  if (action.type === 'ADDRESSBOOK_COMMIT_STAGED_SELECTIONS') {
    const state = getState().addressbookInternal;
    const contacts = state.contacts.contacts;
    const contactKeys = Object.entries(state.ui.stagedSelection.contactData)
      .filter(([
        key,
        value,
      ]) => value)
      .map(([
        key,
        value,
      ]) => key);
    const newContacts = contacts.filter((contact) => contactKeys.includes(contact.id));
    const contactsData = newContacts.map((contact) => ({
      addressbookContactId: contact.id,
    }));
    const guestsById = Object.values(getState().resources.guests.byId);
    const deduplicatedContactsData = contactsData.filter(({
      addressbookContactId,
    }) => (
      !guestsById.find(({
        addressbookContactId: guestAddressbookContactId,
      }) => (
        addressbookContactId === guestAddressbookContactId
      ))
    ));

    dispatch(actions.createGuests(deduplicatedContactsData));
  } else if (action.type === 'ADDRESSBOOK_ADD_CONTACT_FULFILLED') {
    dispatch(actions.createGuests([
      {
        addressbookContactId: action.payload.id,
      },
    ]));
  } else if (action.type === 'ADDRESSBOOK_UPLOAD_CONTACTS_FULFILLED') {
    const contactsData = action.payload.contacts.map((contact) => ({
      addressbookContactId: contact.id,
    }));
    const guestsById = Object.values(getState().resources.guests.byId);

    const newContactData = contactsData.filter((contact) => !guestsById.some((guest) => guest.addressbookContactId === contact.addressbookContactId));

    dispatch(actions.createGuests(newContactData));
  } else if (action.type === 'ADDRESSBOOK_DELETE_CONTACT') {
    const state = getState();
    const guest = find(
      state.resources.guests.byId,
      (guest) => guest.addressbookContactId === action.meta.id,
    );

    dispatch(actions.stageRemoval(guest.id));
    dispatch(actions.commitRemoval(guest.id));
  } else if (includes(syncResourceActions, action.type) && includes(syncResourceModels, action.meta.model)) {
    dispatch(abkActions.fetchGroups());
  }
  next(action);
};

export { addressbookMiddleware };
