import { SmallDivider } from '@minted/fancyclothes';
import React from 'react';

import { lgMarginBottomStyles } from '../../neutral/styles/spacing';

const centered = {
  textAlign: 'center',
};

export default ({
  extraStyles = [], hideDivider = false, isCentered,
}) => (
  <div
    css={
      [
        lgMarginBottomStyles,
        isCentered && centered,
        ...extraStyles,
      ]
    }
    data-cy="secondarySeparator"
  >
    {!hideDivider && (<SmallDivider />)}
  </div>
);
