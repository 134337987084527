import { DesignProvider } from '@minted/fancyclothes';
import merge from 'lodash/merge';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import convertDekuraToFancyClothes from './convertDekuraToFancyClothes';
import FancyClothesContext from './FancyClothesContext';
import FrameContext from '../../core/components/PagePreview/Context';
import { DEFAULT_COLORWAY } from '../../themes/constants';
import { selectSite } from '../selectors';

const selectDesignData = ({
  resources,
  theme,
}) => {
  const {
    mintedProductColorChoiceId,
    themeDataOverrides,
    themeId,
  } = selectSite(resources.sites);

  return {
    colorway: (
      themeId
        ? DEFAULT_COLORWAY
        : theme.colorwayCodeById[mintedProductColorChoiceId]
    ),
    fancyClothesData: theme.fancyClothesData,
    themeDataOverrides,
  };
};

export default ({
  children,
}) => {
  const {
    colorway,
    fancyClothesData: serverSideFancyClothesDesign,
    themeDataOverrides,
  } = useSelector(selectDesignData);

  const design = (
    useMemo(
      () => (
        convertDekuraToFancyClothes(
          themeDataOverrides || {}
        )
      ),
      [
        themeDataOverrides,
      ]
    )
  );

  const fancyClothesData = (
    useMemo(() => ({
      colorway,
      design: (
        merge(
          {},
          serverSideFancyClothesDesign,
          design,
        )
      ),
    }), [
      colorway,
      design,
      serverSideFancyClothesDesign,
    ])
  );

  return (
    <FancyClothesContext.Provider value={fancyClothesData}>
      <FrameContext.Consumer>
        {
          ({
            document,
          }) => (
            <DesignProvider
              colorway={colorway}
              design={fancyClothesData.design}
              styleContainer={document ? document.head : undefined}
            >
              {children}
            </DesignProvider>
          )
        }
      </FrameContext.Consumer>
    </FancyClothesContext.Provider>
  );
};
