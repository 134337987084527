
import { arrayOf, string } from 'prop-types';
import React, { useCallback } from 'react';

import {
  contentConfiguration, ContentPage, contentUtil,
} from '../../content';
import { track } from '../../track/utils';

const Travel = ({
  accommodations, configuration, id, title,
}) => {
  const actionLinkHandler = useCallback(
    () => track('View Travel & Accommodations', 'Travel_Visit_URL_clicked'),
    []
  );

  return (
    <ContentPage
      actionLinkProps={
        {
          onClick: actionLinkHandler,
          text: 'Visit Website',
        }
      }
      configuration={configuration}
      data={accommodations}
      id={id}
      title={title}
    />
  );
};

Travel.propTypes = {
  accommodations: arrayOf(contentUtil.contentPropTypes).isRequired,
  configuration: contentConfiguration.configurationPropTypes,
  id: string.isRequired,
  title: string.isRequired,
};

export default Travel;
