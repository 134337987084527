import { arrayOf, string } from 'prop-types';
import React, { Fragment } from 'react';

import { configurationPropTypes } from './configuration';
import { Content } from './Content';
import { hideLast, hideMd } from './styles';
import { contentPropTypes } from './util';
import { WrapperMedium } from '../layout/website';
import Page from '../pages/website/Page';
import PageContentSeparator from '../pages/website/PageContentSeparator';

export const ContentPage = ({
  actionLinkProps, configuration, data, id, title,
}) => {
  const isCentered = configuration.textAlignment === 'center';

  return (
    <Page
      id={id}
      title={title}
    >
      <WrapperMedium>
        {
          data.map((item) => (
            <Fragment key={item.id}>
              <Content
                actionLinkProps={actionLinkProps}
                configuration={configuration}
                {...item}
              />
              <PageContentSeparator
                extraStyles={
                  [
                    hideLast,
                    !isCentered && hideMd,
                  ]
                }
                hideDivider={id === 'registry'}
                isCentered
              />
            </Fragment>
          ))
        }
      </WrapperMedium>
    </Page>
  );
};

ContentPage.propTypes = {
  actionLinkProps: Content.propTypes.actionLinkProps,
  configuration: configurationPropTypes,
  data: arrayOf(contentPropTypes).isRequired,
  id: string.isRequired,
  title: string.isRequired,
};
