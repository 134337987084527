import { css } from '@emotion/react';
import {

  Button, Icon, theme, GlobalStyles,
} from '@minted/minted-components';
import PropTypes from 'prop-types';
import {
  React, useMemo, useState, useCallback, Fragment,
} from 'react';
import { connect } from 'react-redux';

import CommentCardSection from './CommentCardSection';
import NewCommentForm from './NewCommentForm';
import { SIMPLIFIED_WEBSITE_CONTENT_WIDTH } from '../../constants';
import { getResource, selectSite } from '../../selectors';

const propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  isReadOnly: PropTypes.bool,
  site: PropTypes.object.isRequired,
};

const centeredContainerStyles = css`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const commentButtonContainerStyles = css`
    margin-bottom: ${theme.spacing.x6};
    max-width: ${SIMPLIFIED_WEBSITE_CONTENT_WIDTH};
    width: 100%;
`;

const commentSectionStyles = css`
    padding-left: ${theme.spacing.x4};
    padding-right: ${theme.spacing.x4};
    padding-top: ${theme.spacing.x8};
`;

const countCommentsStyles = css`
    ${theme.typeStyles.bodyLargeStyles};
    inline-size: 50%;
    margin-bottom: ${theme.spacing.x8};
    overflow-wrap: break-word;
    text-align: center;
`;

export const countComments = (comments) => (
  comments.reduce((sum, comment) => (sum + 1 + (comment.replies?.length || 0)), 0)
);

export const CommentSection = ({
  comments,
  isReadOnly,
  site,
}) => {
  const [
    isWritingComment,
    setIsWritingComment,
  ] = useState(false);
  const [
    replyId,
    setReplyId,
  ] = useState(undefined);

  const closeDrawer = (
    useCallback(() => {
      setIsWritingComment(false);
    }, [])
  );

  const startParentComment = (
    useCallback(() => {
      setIsWritingComment(true);
      setReplyId(undefined);
    }, [])
  );
  const startReplyComment = (
    useCallback((replyId) => () => {
      setIsWritingComment(true);
      setReplyId(replyId);
    }, [])
  );

  const countOfComments = useMemo(() => countComments(comments), [
    comments,
  ]);

  const commentText = useMemo(() => {
    if (countOfComments > 0) {
      return `${countOfComments} comment${countOfComments === 1 ? '' : 's'}`;
    }

    return 'Be the first one to write a comment';
  }, [
    countOfComments,
  ]);

  return (
    <div
      css={commentSectionStyles}
      id="comment_section"
    >
      <GlobalStyles />
      <div css={centeredContainerStyles}>
        <div css={countCommentsStyles}>
          {commentText}
        </div>
        {
          !isReadOnly && (
            <Fragment>
              <div css={commentButtonContainerStyles}>
                <Button
                  expand
                  iconType={Icon.types.caption}
                  onClick={startParentComment}
                  text="Write a comment"
                  type={Button.types.primary}
                />
              </div>
              <NewCommentForm
                closeDrawer={closeDrawer}
                isWritingComment={isWritingComment}
                replyId={replyId}
              />
            </Fragment>
          )
        }
        <CommentCardSection
          comments={comments}
          isDeleteEnabled={site.isTenant && site.status !== 'archived'}
          isReadOnly={isReadOnly}
          startReplyComment={startReplyComment}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const comments = getResource(state.resources.comments);
  const site = selectSite(state.resources.sites);

  return {
    comments,
    site,
  };
};

CommentSection.propTypes = propTypes;

export default connect(mapStateToProps)(CommentSection);
