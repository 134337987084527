export const commentValidations = [
  {
    errorMessage: 'Required',
    getIsValid: ({
      value,
    }) => (
      value
    ),
  },
];

// Pulled from Minted Components here: https://gitlab.com/minted/frontend/user-interface-and-experience/minted-components/blob/a177f64c85924694d0907e03f8974d0d70f493cf/src/components/Onboarding/validations.js#L2
export const emailRegexp = /^[a-zA-Z0-9_\-.+^!#$%&*+/=?`|{}~']+@((?:\w+([-.]\w+)*\.\w+([-.]\w+)*)+|(\[([0-9]{1,3}(\.[0-9]{1,3}){3}|[0-9a-fA-F]{1,4}(:[0-9a-fA-F]{1,4}){7})]))/;

export const emailValidations = [
  {
    errorMessage: 'Required',
    getIsValid: ({
      value,
    }) => (
      value
    ),
  },
  {
    errorMessage: 'Email is invalid',
    getIsValid: ({
      value,
    }) => (
      emailRegexp.test(value)
    ),
  },
];

const nameRegexp = /[^ a-zA-Z'-]\d/g;

export const nameValidations = [
  {
    errorMessage: 'Required',
    getIsValid: ({
      value,
    }) => (
      value
    ),
  },
  {
    errorMessage: 'Name contains invalid characters',
    getIsValid: ({
      value,
    }) => (
      !nameRegexp.test(value)
    ),
  },
  {
    errorMessage: 'Name contains over 50 characters.',
    getIsValid: ({
      value,
    }) => (
      value.length <= 50
    ),
  },
];
