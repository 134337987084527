import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import partition from 'lodash/partition';
import union from 'lodash/union';
import { handleActions } from 'redux-actions';

/*
 * Divides collections into a two element list where the first element is the collection that
 * matches the params passed in and the second element is a list of the other collections.
 * Used to conveniently modify a target collection.
*/
const partitionCollections = (state, params) => {
  const [
    [
      entry = {
        ids: [],
      },
    ],
    others,
  ] = partition(state, (item) => isEqual(item.params, params));

  return [
    entry,
    others,
  ];
};

const initialState = [];

export default handleActions({
  COMMIT_RESOURCE_CREATION_FULFILLED: (state, {
    meta, payload,
  }) => {
    // TODO: This handler updates collections with newly created items. This might
    // break some existing pages so it's being disabled by default. Pull this check
    // out once we verify that it works everywhere.
    if (!meta.optimistic) {
      return state;
    }

    const [
      entry,
      others,
    ] = partitionCollections(state, meta.params);

    return [
      ...others,
      {
        ...entry,
        ids: [
          ...entry.ids,
          payload.id,
        ],
      },
    ];
  },
  COMMIT_RESOURCE_REMOVAL_FULFILLED: (state, {
    meta, payload,
  }) => {
    // TODO: This handler removes deleted items from existing collections. This might
    // break some existing pages so it's being disabled by default. Pull this check
    // out once we verify that it works everywhere.
    if (!meta.optimistic) {
      return state;
    }

    return state.map((collection) => ({
      ...collection,
      ids: collection.ids.filter((id) => id !== meta.id),
    }));
  },
  FETCH_RESOURCE_FULFILLED: (state, {
    meta, payload,
  }) => ([
    ...state.filter((item) => !isEqual(item.params, meta.params)),
    {
      fetchTime: meta.time,
      ids: map(payload, 'id'),
      isFetching: false,
      params: {
        ...meta.params,
      },
    },
  ]),
  FETCH_RESOURCE_PENDING: (state, {
    meta,
  }) => {
    const [
      entry,
      others,
    ] = partitionCollections(state, meta.params);

    return [
      ...others,
      {
        fetchTime: null,
        ids: entry.ids,
        isFetching: true,
        params: {
          ...meta.params,
        },
      },
    ];
  },
  INVALIDATE_COLLECTIONS: (state) => state.map((item) => ({
    ...item,
    fetchTime: null,
  })),
  MERGE_RESOURCES: (state, {
    meta, payload,
  }) => {
    const [
      entry,
      others,
    ] = partitionCollections(state, meta.params);

    return [
      ...others,
      {
        ...entry,
        ids: union(entry.ids, map(payload, 'id')),
      },
    ];
  },
}, initialState);
