import pick from 'lodash/pick';

import { selectStagedCreation } from './selectors';
import API from '../core/api';

// Pulls errors out of APIError response
const formatErrors = (error) => {
  if (error.name === 'APIError') {
    return error.response.body;
  }

  return error;
};

export const fetchResource = (name, path, params = {}, apiOverrides, casingIgnorePaths) => {
  params = params || {};

  return (dispatch) => dispatch({
    meta: {
      model: name,
      params,
      time: Date.now(),
    },
    payload: {
      promise: new Promise((resolve, reject) => {
        API.get(path, apiOverrides, params, casingIgnorePaths).then(
          (result) => resolve(result),
          reject
        );
      }),
    },
    type: 'FETCH_RESOURCE',
  });
};

export const createResource = (name, data, apiOverrides) => (dispatch) => dispatch({
  meta: {
    model: name,
  },
  payload: {
    promise: new Promise((resolve, reject) => {
      API.post(name, apiOverrides, data).then(
        (result) => resolve(result),
        (error) => reject(formatErrors(error)),
      );
    }),
  },
  type: 'CREATE_RESOURCE',
});

// TODO: Remove apiOverrides when we port image handling to new API
export const deleteResource = (name, path, id, apiOverrides) => (dispatch) => dispatch({
  meta: {
    id,
    model: name,
  },
  payload: {
    promise: new Promise((resolve, reject) => {
      API.delete(`${path}/${id}`, null, apiOverrides).then(
        (result) => resolve(result),
        (error) => reject(formatErrors(error))
      );
    }),
  },
  type: 'DELETE_RESOURCE',
});

export const updateResource = (name, id, data, apiOverrides, casingIgnorePaths, patch) => (dispatch) => dispatch({
  meta: {
    id,
    model: name,
  },
  payload: {
    promise: new Promise((resolve, reject) => {
      const args = [
        `${name}/${id}`,
        apiOverrides,
        data,
        casingIgnorePaths,
      ];
      const action = patch ? API.patch(...args) : API.put(...args);

      action.then(
        (result) => resolve(result),
        (error) => reject(formatErrors(error))
      );
    }),
  },
  type: 'UPDATE_RESOURCE',
});

export const stageChanges = (name, id, data, options = {}) => {
  const meta = {
    id,
    model: name,
  };

  // Changes will immediately be commited. Will not trigger `editing` flag on collections.
  if (options.silent) {
    meta['silent'] = true;
  }

  if (options.patch) {
    meta['patch'] = true;
  }

  if (options.deepMergeState) {
    meta['deepMergeState'] = true;
  }

  return (dispatch) => dispatch({
    meta,
    payload: data,
    type: 'STAGE_RESOURCE_UPDATE',
  });
};

export const clearChanges = (name, id, patchFields) => (dispatch) => dispatch({
  meta: {
    id,
    model: name,
    patchFields,
  },
  payload: {
    id,
  },
  type: 'CLEAR_RESOURCE_UPDATE',
});

export const commitChanges = (name, id, transform, apiOverrides, casingIgnorePaths = [], patchFields) => (dispatch, getState) => {
  const resource = getState().resources[name];
  const update = resource.staged.update[id];
  let data = update.data;

  if (patchFields && !update.patch) {
    console.error('patchFields is only valid on staged updates marked as patches.');
  }

  if (transform !== undefined) {
    data = transform(data);
  }

  if (patchFields) {
    data = pick(data, patchFields);
  }

  return dispatch({
    meta: {
      id,
      model: name,
      patchFields,
    },
    payload: {
      promise: new Promise((resolve, reject) => {
        dispatch(updateResource(name, id, data, apiOverrides, casingIgnorePaths, update.patch)).then(
          ({
            value,
          }) => resolve(value),
          (error) => reject(formatErrors(error)),
        );
      }),
    },
    type: 'COMMIT_RESOURCE_UPDATE',
  }).then(({
    value,
  }) => {
    dispatch(clearChanges(name, id, patchFields));

    return (value);
  });
};

export const stageRemoval = (name, id) => (dispatch) => dispatch({
  meta: {
    id,
    model: name,
  },
  payload: {
    id,
  },
  type: 'STAGE_RESOURCE_REMOVAL',
});

export const clearRemoval = (name, id) => (dispatch) => dispatch({
  meta: {
    id,
    model: name,
  },
  payload: {
    id,
  },
  type: 'CLEAR_RESOURCE_REMOVAL',
});

// TODO: serverSideId is here for legacy endpoints that use something other than ID for removal, like a uuid
// TODO: apiOverrides is here to deal with the legacy api not handling trailling slashes
// TODO: Remove both of these params when we port image API functions to the new API
// TODO: Remove optimistic option and make it the only behavior once we know it won't
// break things
export const commitRemoval = (name, path, id, serverSideId, apiOverrides, optimistic = false) => (dispatch) => dispatch({
  meta: {
    id,
    model: name,
    optimistic,
  },
  payload: {
    promise: new Promise((resolve, reject) => {
      dispatch(deleteResource(name, path, serverSideId || id, apiOverrides)).then(
        ({
          value,
        }) => resolve(value),
        reject,
      );
    }),
  },
  type: 'COMMIT_RESOURCE_REMOVAL',
}).then(() => {
  const promises = [
    dispatch(clearRemoval(name, id)),
    dispatch(clearChanges(name, id)),
  ];

  if (!optimistic) {
    promises.push(dispatch(invalidateCollections(name)));
  }

  Promise.all(promises);
});

export const reorderResource = (name, data, apiOverrides) => (dispatch) => dispatch({
  meta: {
    model: name,
  },
  payload: {
    data,  // Optimistic update
    promise: new Promise((resolve) => {
      if (data.length > 0) {
        API.put(`${name}/sort/`, apiOverrides, data).then((result) => {
          resolve(result);
        });
      } else {
        resolve([]);
      }
    }),
  },
  type: 'REORDER_RESOURCE',
});

export const stageCreation = (name, data, params = {}) => (dispatch) => dispatch({
  meta: {
    model: name,
    params,
  },
  payload: data,
  type: 'STAGE_RESOURCE_CREATION',
});

export const clearCreation = (name, params) => (dispatch) => dispatch({
  meta: {
    model: name,
    params,
  },
  payload: {},
  type: 'CLEAR_RESOURCE_CREATION',
});

// TODO: See if not invalidating breaks anything and we need an override
// TODO: Remove optimistic option and make it the only behavior once we know it won't
// break things
export const commitCreation = (name, params, transform, apiOverrides, optimistic = false) => (dispatch, getState) => {
  const resource = getState().resources[name];
  let data = selectStagedCreation(params, resource).data;

  if (transform !== undefined) {
    data = transform(data);
  }

  return dispatch({
    meta: {
      model: name,
      optimistic,
      params,
    },
    payload: {
      promise: new Promise((resolve, reject) => {
        dispatch(createResource(name, data, apiOverrides)).then(
          ({
            value,
          }) => resolve(value),
          reject,
        );
      }),
    },
    type: 'COMMIT_RESOURCE_CREATION',
  }).then(({
    value,
  }) => {
    const promises = [
      dispatch(clearCreation(name, params)),
    ];

    if (!optimistic) {
      promises.push(dispatch(invalidateCollections(name)));
    }
    Promise.all(promises);

    if (name === 'sections' || 'videos') {
      return value;
    }
  });
};

export const invalidateCollections = (name) => (dispatch) => dispatch({
  meta: {
    model: name,
  },
  payload: null,
  type: 'INVALIDATE_COLLECTIONS',
});

export const customGet = (path, params, progressKey, apiOverrides) => (dispatch) => dispatch({
  meta: {
    progressKey,
  },
  payload: {
    promise: new Promise((resolve, reject) => {
      API.get(path, apiOverrides, params).then(
        (result) => resolve(result),
        (error) => reject(formatErrors(error)),
      );
    }),
  },
  type: 'CUSTOM_API_REQUEST',
});

export const customPost = (path, data, progressKey, apiOverrides) => (dispatch) => dispatch({
  meta: {
    progressKey,
  },
  payload: {
    promise: new Promise((resolve, reject) => {
      API.post(path, apiOverrides, data).then(
        (result) => resolve(result),
        (error) => reject(formatErrors(error)),
      );
    }),
  },
  type: 'CUSTOM_API_REQUEST',
});

export const customPut = (path, data, progressKey, apiOverrides) => (dispatch) => dispatch({
  meta: {
    progressKey,
  },
  payload: {
    promise: new Promise((resolve, reject) => {
      API.put(path, apiOverrides, data).then(
        (result) => resolve(result),
        (error) => reject(formatErrors(error)),
      );
    }),
  },
  type: 'CUSTOM_API_REQUEST',
});

export const customDelete = (path, data, progressKey, apiOverrides) => (dispatch) => dispatch({
  meta: {
    progressKey,
  },
  payload: {
    promise: new Promise((resolve, reject) => {
      API.delete(path, data, apiOverrides).then(
        (result) => resolve(result),
        (error) => reject(formatErrors(error)),
      );
    }),
  },
  type: 'CUSTOM_API_REQUEST',
});

export default {
  clearChanges,
  clearRemoval,
  commitChanges,
  commitRemoval,
  createResource,
  deleteResource,
  fetchResource,
  invalidateCollections,
  reorderResource,
  stageChanges,
  stageRemoval,
  updateResource,
};
