import isEqual from 'lodash/isEqual';

/*
We noticed substantial performance degradation using input fields (especially RichText) on the new Wedding Websites customize view. This happens because our state updates in Redux are returning new objects in mapStateToProps site-wide. That has a side-effect of causing mass-re-renders across the entire React node tree.

We came up with a number of methods to mitigate this problem:

1. Use Reselect (didn't work because even our reducers are outputting new objects when the state hasn't changed).
2. Come up with our own customized memoization strategy (the fix used in this PR).
3. Utilize setTimeout with cancelation to limit paints in the browser until after React finished processing changes (we had a prototype of this which worked fine, but decided to fix the real problem first).
4. Move most of the processing in mapStateToProps into reducers so it's done once. This would require a middleware library like Redux-Observable to get it right, but we'd still have the problem of recreating objects instead of returning the previous state (in the case of no changes).
5. While these problems also occurred on the new Details and Settings, the performance impact is negligible by the fact that only one form appeared on the page and only one set of inputs updated with these changes.

We believe the reason Draft.js never had these issues and Slate does is because Draft.js debounces updates to Redux.
*/

export default () => {
  let previousState;

  return (
    state,
  ) => {
    const nextState = (
      // NOTE: Deep equals is temporary until we create a customized solution.
      isEqual(previousState, state)
        ? previousState
        : state
    );

    previousState = nextState;

    return nextState;
  };
};
