import isEmpty from 'lodash/isEmpty';
import {
  array, shape, string,
} from 'prop-types';

export const contentPropTypes = shape({
  description: array,
  image: string,
  title: string.isRequired,
  url: string,
}).isRequired;

export const isImageVerticallyPositioned = ({
  imageAlignment,
}) => (
  imageAlignment === 'top' || imageAlignment === 'bottom'
);

export const extractNonemptyBlocks = (data) => (
  data
    .map((item) => {
      if (isEmpty(item)) {
        return '';
      }

      return item.description.blocks.map((block) => block.text).join('\n');
    })
    .filter((item) => !isEmpty(item))
);
