import axios from 'axios';
import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import omitBy from 'lodash/omitBy';
import transform from 'lodash/transform';
import words from 'lodash/words';
import urljoin from 'url-join';

import { APIError } from './errors';
import { makeQueryString } from './utils';

const UUID_RE = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const deepKeyUpdate = (obj, func, handlers = {}, casingIgnorePaths = []) => {
  if (!isObject(obj) && !isArray(obj)) {
    return obj;
  }

  return transform(obj, (acc, value, key) => {
    // do not convert case for the key if it is a UUID, but continue processing children
    const modifiedKey = (
      UUID_RE.test(key)
        ? key
        : func(key)
    );

    if (
      casingIgnorePaths.length > 0
      && (
        casingIgnorePaths.includes(modifiedKey)
        || casingIgnorePaths.includes(key)
      )
    ) {
      acc[modifiedKey] = value;
    } else if (isPlainObject(value)) {
      acc[modifiedKey] = deepKeyUpdate(value, func, handlers, casingIgnorePaths);
    } else if (isArray(value)) {
      acc[modifiedKey] = value.map((item) => deepKeyUpdate(item, func, handlers, casingIgnorePaths));
    } else {
      acc[modifiedKey] = value;
    }
  });
};

const deepCamelCaseKeys = (obj, casingIgnorePaths) => deepKeyUpdate(
  obj,
  camelCase,
  {},
  casingIgnorePaths,
);

const snakeCase = (text) => words(text).reduce((result, word, index) => (
  result + (index > 0 ? '_' : '') + word.toLowerCase()
), '');

const deepSnakeCase = (obj, casingIgnorePaths) => deepKeyUpdate(
  obj,
  snakeCase,
  {},
  casingIgnorePaths,
);

const makeUrl = (url, params, settings) => {
  const args = [
    settings.scheme,
    settings.domain,
    settings.basePath,
    url,
  ];

  if (settings.trailingSlash) {
    args.push('/');
  }
  url = urljoin(...args);

  return `${url}${makeQueryString(params, settings.hasMultipleQueryParamValues || false)}`;
};

const call = (resource, params, apiSettings, requestOptions, casingIgnorePaths) => {
  // Filter out headers that have null or undefined values
  // using == 'null' here to catch 'null' and 'undefined' but not empty strings
  const headers = omitBy(apiSettings.headers, (value, key) => value == null);

  const options = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      ...headers,
    },
    responseType: 'json',
    url: makeUrl(resource, params, apiSettings),
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    ...requestOptions,
  };

  return axios(options).then((response) => {
    const data = (
      response.data
        ? deepCamelCaseKeys(response.data, casingIgnorePaths)
        : {}
    );

    if (apiSettings.useHeaderPagination && response.headers.hasOwnProperty('x-total-count')) {
      return {
        data,
        totalCount: Number(response.headers['x-total-count']),
      };
    }

    if (apiSettings.returnCookies) {
      return {
        cookies: response.headers['set-cookie'],
        data,
      };
    }

    return data;
  }).catch((error) => {
    if (error.response != null) {
      const data = (
        error.response.data
          ? deepCamelCaseKeys(error.response.data, casingIgnorePaths)
          : {}
      );

      const _response = {
        body: data,
        headers: error.response.config.headers,
        status: error.response.status,
        url: error.response.config.url,
      };

      const _request = {
        body: params,
        headers: options.headers,
      };

      throw new APIError(error.response.statusText, _response, _request);
    }

    throw error;
  });
};

const get = (resource, params, settings, casingIgnorePaths) => call(resource, params, settings, {
  method: 'get',
}, casingIgnorePaths);

const post = (resource, data, settings, casingIgnorePaths) => {
  const options = {
    data: deepSnakeCase(data, casingIgnorePaths),
    method: 'post',
  };

  return call(resource, null, settings, options, casingIgnorePaths);
};

const patch = (resource, data, settings, casingIgnorePaths) => {
  const options = {
    data: deepSnakeCase(data, casingIgnorePaths),
    method: 'patch',
  };

  return call(resource, null, settings, options, casingIgnorePaths);
};

const put = (resource, data, settings, casingIgnorePaths) => {
  const options = {
    data: deepSnakeCase(data, casingIgnorePaths),
    method: 'put',
  };

  return call(resource, null, settings, options, casingIgnorePaths);
};

const _delete = (resource, data, settings) => {
  const options = {
    data: deepSnakeCase(data),
    method: 'delete',
  };

  return call(resource, null, settings, options);
};

const options = (resource, settings) => {
  const options = {
    method: 'options',
  };

  return call(resource, null, settings, options);
};

export default {
  delete: _delete,
  get,
  makeUrl,
  options,
  patch,
  post,
  put,
};
