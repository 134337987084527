import { css } from '@emotion/react';
import { theme } from '@minted/minted-components';
import PropTypes from 'prop-types';
import React from 'react';

const style = css`
  padding: 0 15px;
  flex-grow: 1;
  flex-basis: 0;
  order: 2;

  ${theme.media.greaterThan(theme.breakpoints.small)(`
    order: inherit;
  `)}
`;

const Column = ({
  children, className,
}) => (
  <div
    className={className}
    css={style}
  >
    {children}
  </div>
);

Column.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default Column;
