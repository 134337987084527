import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { handleActions } from 'redux-actions';

const initialState = {};

const reorderReducer = (state = {}, {
  payload,
}) => (
  mapValues(
    state,
    (value, key) => ({
      ...value,
      position: payload.find((item) => item.id.toString() === key.toString())?.position || state[key].position,
    })
  )
);

export default handleActions({
  COMMIT_RESOURCE_CREATION_FULFILLED: (state, {
    meta, payload,
  }) => ({
    ...state,
    [payload.id]: payload,
  }),
  CREATE_RESOURCE_FULFILLED: (state, {
    payload,
  }) => ({
    ...state,
    [payload.id]: payload,
  }),
  DELETE_RESOURCE_FULFILLED: (state, {
    meta,
  }) => (
    omit(state, meta.id)
  ),
  FETCH_RESOURCE_FULFILLED: (state, {
    payload,
  }) => ({
    ...state,
    ...keyBy(payload, 'id'),
  }),
  MERGE_RESOURCES: (state, {
    payload,
  }) => (merge({}, state, keyBy(payload, 'id'))),
  REORDER_RESOURCE_FULFILLED: reorderReducer,
  REORDER_RESOURCE_PENDING: reorderReducer,
  UPDATE_RESOURCE_FULFILLED: (state, {
    payload,
  }) => ({
    ...state,
    [payload.id]: payload,
  }),
}, initialState);
