import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';

export const defaultSortKey = 'position';

export const sortedItems = (items, sortKey = defaultSortKey) => sortBy(values(items), sortKey);

export const selectStagedUpdate = (item, resource) => {
  const staged = resource.staged.update[item.id];

  if (staged === undefined) {
    return {};
  }

  if (staged.silent) {
    return {
      data: staged.data,
    };
  }

  const {
    data,
    patch,
    ...update
  } = staged;

  return {
    data,
    patch,
    update,
  };
};

const selectStagedRemoval = (item, resource) => {
  const staged = resource.staged.delete[item.id];

  return (
    staged === undefined
      ? {}
      : {
        delete: staged,
      }
  );
};

export const selectStagedCreation = (params, resource) => {
  const filteredItems = find(resource.staged.create, (item) => isEqual(item.params, params));

  return !isEmpty(params) || resource.staged.create.length === 0 ? filteredItems : resource.staged.create[0];
};

export const selectRecord = (resource, item) => {
  // Merge staged data and status into item if it's present
  const staged = selectStagedUpdate(item, resource);

  const result = {
    ...selectStagedRemoval(item, resource),
  };

  if (staged.patch) {
    result.data = merge({}, item, staged.data);
    result.stagedFieldNames = Object.keys(staged.data);
    result.update = staged.update;
  } else if (staged.data) {
    result.data = staged.data;
    result.update = staged.update;
  } else {
    result.data = item;
  }

  return result;
};

const selectCollectionDefaultOpts = {
  includeStaged: true,
  sort: true,
  sortKey: defaultSortKey,
};

export const selectCollection = (resource, params = {}, opts = {}) => {
  const options = {
    ...selectCollectionDefaultOpts,
    ...opts,
  };
  const _collection = find(resource.collections, (item) => isEqual(item.params, params));

  if (_collection === undefined) {
    return {
      fetchTime: null,
      hasStagedChanges: false,
      isFetching: false,
      items: [],
      params,
      resource,
      shouldFetch: true,
    };
  }

  const records = pick(resource.byId, _collection.ids);
  const reverse = options.sortKey.indexOf('-') === 0;

  let sorted = records;

  if (options.sort) {
    sorted = sortedItems(records, options.sortKey);
    sorted = reverse ? sorted.reverse() : sorted;
  }

  const stagedItems = sorted.map((item) => selectRecord(resource, item));
  const committedItems = sorted.map((item) => ({
    data: item,
  }));

  const items = (
    options.includeStaged
      ? stagedItems
      : committedItems
  );

  const _result = {
    fetchTime: _collection.fetchTime,
    hasStagedChanges: (
      items
        .find((item) => (
          options.patchFields
            ? (
              (
                item.stagedFieldNames
            && (
              options.patchFields
                .find((patchField) => (
                  item.stagedFieldNames.includes(patchField)
                ))
            )
              )
          || (
            item.update
            && item.update.errors
            && (
              options.patchFields
                .find((patchField) => (
                  item.update.errors[patchField]
                ))
            )
          )
          || item.delete
            )
            : (
              item.update
          || item.delete
            )
        )) !== undefined
    ),
    isFetching: _collection.isFetching,
    items,
    options,
    params,
    resource,
    shouldFetch: _collection.fetchTime === null && !_collection.isFetching,
  };

  return _result;
};
