import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageTransitionManager from './PageTransitionManager';
import Login from '../../login/website/Login';

class LoginWrapper extends Component {
  shouldComponentUpdate(nextProps) {
    const route = this.props.route;
    const nextRoute = nextProps.route;

    return route.name !== nextRoute.name &&
          (route.name === 'login' || nextRoute.name === 'login');
  }

  renderChild() {
    return (
      <PageTransitionManager isCustomizePage={this.props.isCustomizePage}>
        {this.props.children}
      </PageTransitionManager>
    );
  }

  render() {
    const {
      route,
    } = this.props;

    return route.name === 'login' ? <Login /> : this.renderChild();
  }
}

LoginWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isCustomizePage: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  route: state.router.route,
});

export default connect(mapStateToProps)(LoginWrapper);
