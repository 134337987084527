import ReactGA from 'react-ga';
import createRouter from 'router5';
import browserPlugin from 'router5/plugins/browser';
import listenersPlugin from 'router5/plugins/listeners';

import { SITE_TYPES } from '../core/constants';

export const createRoutes = (pages) => pages.map(({
  slug,
}) => (
  // The path for Home is '/' rather than '/home'
  {
    name: slug,
    path: `/${slug === 'home' ? '' : slug}`,
  }
));

// Used only in wedding sites, in the WebsiteHeader component
export const isRouteActive = ({
  isCustomizePage, router, slug,
}) => {
  if (isCustomizePage) { // Translate page slugs to customize routes
    return (
      slug === 'home' ?
        router.isActive('pages', {}, true) || router.isActive('pages.home') :
        router.isActive(`pages.${slug}`)
    );
  }

  return router.isActive(slug);
};

export const getSlugFromRoute = ({
  isCustomizePage, routeName, siteType,
}) => {
  if (isCustomizePage && siteType === SITE_TYPES.WEDDING) {
    return routeName === 'pages' ? 'home' : routeName.replace(/pages\./, '');
  }

  return routeName;
};

const logGAPageView = () => {
  if (typeof document !== 'undefined') {
    ReactGA.set({
      page: window.location.pathname,
    });
    ReactGA.pageview(window.location.pathname);
  }
};

export const makeAuthStartState = (url) => {
  if (url.indexOf('/login') === 0) {
    return url;
  }

  return {
    name: 'login',
    params: {
      next: url,
    },
  };
};

export default (routes, base, defaultRoute) => {
  const router = createRouter(routes, {
    defaultRoute: defaultRoute || 'home',
  })
    .usePlugin(browserPlugin({
      useHash: false,
    }))
    .usePlugin(listenersPlugin())
    .addListener(logGAPageView);

  router.setRootPath(base);

  return router;
};
