import { arrayOf, string } from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import {
  contentConfiguration, ContentPage, contentUtil,
} from '../../content';
import createMemoizer from '../../core/dashboard/createMemoizer';
import { selectCollection } from '../../resources/selectors';

const WebsiteCustomPage = ({
  configuration, id, sections, title,
}) => {
  const sortedSections = useMemo(
    () => (
      sections.sort(
        (sectionA, sectionB) => (
          sectionA.position - sectionB.position
        )
      )
    ),
    [
      sections,
    ]
  );

  return (
    <ContentPage
      configuration={configuration}
      data={sortedSections}
      id={id}
      title={title}
    />
  );
};

WebsiteCustomPage.propTypes = {
  configuration: contentConfiguration.configurationPropTypes,
  id: string.isRequired,
  sections: arrayOf(contentUtil.contentPropTypes).isRequired,
  title: string.isRequired,
};

const memoizeSections = createMemoizer();

const mapStateToProps = (state, {
  page,
}) => {
  const sections = memoizeSections(
    selectCollection(state.resources.sections, {
      page,
    }, {
      includeStaged: true,
    }).items.map((section) => ({
      ...section.data,
      description: section.data.content,
    }))
  );

  return {
    sections,
  };
};

export default connect(mapStateToProps)(WebsiteCustomPage);
