import { DesignComponent } from '@minted/fancyclothes';
import PropTypes, {
  arrayOf, object, shape, string,
} from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import InfoPopup from './InfoPopup';
import { focusLocation } from '../../events/actions';

const MARKER_WIDTH = 40;
const MARKER_HEIGHT = 50;
const MARKER_LEFT = -0.5 * MARKER_WIDTH;  // center horizontally
const MARKER_TOP = -1 * MARKER_HEIGHT;    // show above actual location

// TODO: Missing drop shadow effect because SVG filters don't seem to work right with React JSX
class Marker extends DesignComponent {
  render() {
    const {
      dispatch,
      events,
      index,
      onCloseClick,
      showInfo,
    } = this.props;
    const {
      fontFamily,
    } = this.getFontStyles({}, 'DesignProvider');

    const style = {
      marker: {
        cursor: 'pointer',
        height: MARKER_HEIGHT,
        left: MARKER_LEFT,
        position: 'absolute',
        textAlign: 'center',

        top: MARKER_TOP,
        width: MARKER_WIDTH,
      },
      svg: {
        fill: this.getRule('fontColor', 'DesignProvider'),
        left: 0,
        position: 'absolute',
        top: 0,
      },
      text: {
        color: this.getRule('backgroundColor', 'DesignProvider'),
        display: 'block',
        fontFamily,
        position: 'relative',
        top: '30%',

        transform: 'translateY(-20%)',
        width: 40,
        ...this.getFontSizeStyles(16, {}, 'DesignProvider'),
      },
    };

    return (
      <div>
        <div
          css={style.marker}
          onClick={() => dispatch(focusLocation(index))}
        >
          <svg
            css={style.svg}
            height="50px"
            width="40px"
            x="0px"
            y="0px"
          >
            <filter id="dropshadow">
              <feGaussianBlur
                in="SourceAlpha"
                stdDeviation="1"
              />
              <feOffset
                dx="0"
                dy="0"
                result="offsetblur"
              />
              <feComponentTransfer>
                <feFuncA
                  slope="0.4"
                  type="linear"
                />
              </feComponentTransfer>
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
            <path
              d="M20,2C11.7,2,5,8.4,5,16.4C5,30.1,20,48,20,48s15-17.9,15-31.6C35,8.4,28.3,2,20,2z"
              style={
                {
                  filter: 'url(#dropshadow)',
                }
              }
            />
            {
              events.length > 1 && (
                <circle
                  cx="50%"
                  cy="40%"
                  fill="none"
                  r="25%"
                  stroke="#fff"
                  strokeWidth="5%"
                />
              )
            }
          </svg>
          <span css={style.text}>
            {events.length > 1 ? '+' : `${index + 1}`}
          </span>
        </div>
        {
          showInfo && (
            <InfoPopup
              events={events}
              onCloseClick={onCloseClick}
            />
          )
        }
      </div>
    );
  }
}

Marker.propTypes = {
  events: arrayOf(
    shape({
      address1: string.isRequired,
      address2: string,
      city: string.isRequired,
      date: string.isRequired,
      description: object,
      state: string.isRequired,
      time: string.isRequired,
      title: string.isRequired,
      venue: string.isRequired,
      zipCode: string.isRequired,
    }).isRequired
  ).isRequired,
  index: PropTypes.number.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  showInfo: PropTypes.bool,
};

Marker.MARKER_LEFT = MARKER_LEFT;
Marker.MARKER_TOP = MARKER_TOP;

export default connect()(Marker);
